/* global $FPROM */
import { take, call, fork, cancel, takeLatest, put } from 'redux-saga/effects';
import config from '@werter12/naturaltts-config';

import {
  SIGNUP_REQUEST,
  SIGNUP_FAIL,
  SIGNIN_REQUEST,
  SIGNIN_FAIL,
  LOGOUT,
  signUpResult,
  signUpFail,
  signInResult,
  signInFail,
  completeSignUpRequest,
  completeSignUpResult,
  completeSignUpFail,
  forgotPasswordResult,
  forgotPasswordFail,
  googleLoginResult,
  googleLoginFail,
  resetPasswordResult,
  resetPasswordFail,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAIL,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_FAIL,
  FAILED_3D_SECURE,
  failed3DSecure,
  start3DSecure,
  SIGNUP_STACK_SOCIAL_REQUEST,
  SIGNUP_STACK_SOCIAL_FAIL,
  signUpStackSocialResult,
  signUpStackSocialFail
} from '../actions/authActions';
import {
  callSignUp,
  callSignIn,
  callGoogleLogin,
  callForgotPassword,
  callResetPassword,
  callSignUpStackSocial,
  asyncFlow
} from '../api';
import Storage from '../../storage';
import { sagaErrorBoundaries, manage3DSecurePayment } from '../utils';
import history from '../../utils/history';

function* authEffectWithDashboardRedirect(data) {
  yield call(Storage.setItem, 'NATURALTTS_USER', data.user, true);
  yield call(history.push, '/convert');
}

function* authEffectWithRedirect(data) {
  yield call(Storage.setItem, 'NATURALTTS_USER', data.user, true);
  yield call(history.push, '/confirmation', { signUp: true });
  if (
    data.user.plan === config.PLANS.personal.shortName ||
    (data.user.plan === config.PLANS.commercial.shortName && $FPROM)
  ) {
    yield call(
      $FPROM.trackSignup,
      {
        email: data.user.email
      },
      function() {
        console.log('$FPROM Callback received!');
      }
    );
  }
}

const createAuthSignUpEffect = stripe =>
  function* authSignUpEffect(data) {
    console.log('authSignUpEffect', data);
    if (data.subscription && data.user) {
      const { latest_invoice, id } = data.subscription;
      const { username, email, password, plan } = data.user;
      const { payment_intent } = latest_invoice;
      const { client_secret, status } = payment_intent;

      if (status === 'requires_action') {
        yield put(start3DSecure());
        const error = yield call(manage3DSecurePayment, stripe, client_secret);
        if (error) {
          yield put(failed3DSecure(id, error));
        } else {
          yield put(
            completeSignUpRequest({
              username,
              email,
              password,
              plan,
              userSubscriptionId: id
            })
          );
        }
      }
    } else {
      yield call(authEffectWithRedirect, data);
    }
  };

const authSignInFlow = asyncFlow(
  callSignIn,
  signInResult,
  signInFail,
  authEffectWithDashboardRedirect
);

const authGoogleLoginFlow = asyncFlow(
  callGoogleLogin,
  googleLoginResult,
  googleLoginFail,
  authEffectWithDashboardRedirect
);

const forgotPasswordFlow = asyncFlow(
  callForgotPassword,
  forgotPasswordResult,
  forgotPasswordFail
);

const resetPasswordFlow = asyncFlow(
  callResetPassword,
  resetPasswordResult,
  resetPasswordFail
);

const completeAuthSignUpFlow = asyncFlow(
  callSignUp,
  completeSignUpResult,
  completeSignUpFail,
  authEffectWithRedirect
);

function* authLogoutFlow() {
  yield call(Storage.clearItem, 'NATURALTTS_USER');
}

const signUpStackSocialFlow = asyncFlow(
  callSignUpStackSocial,
  signUpStackSocialResult,
  signUpStackSocialFail,
  authEffectWithDashboardRedirect
);

function* watchAuth() {
  while (true) {
    const {
      name,
      email,
      password,
      tokenId,
      type,
      plan,
      paymentMethod,
      stripe,
      userSubscriptionId,
      code
    } = yield take([
      SIGNUP_REQUEST,
      SIGNIN_REQUEST,
      GOOGLE_LOGIN_REQUEST,
      SIGNUP_STACK_SOCIAL_REQUEST
    ]);
    const authData = {
      username: name,
      email,
      password,
      plan,
      paymentMethod,
      userSubscriptionId
    };
    console.log('planAuthSaga', plan);
    let task;
    console.log('authData', authData);
    switch (type) {
      case SIGNIN_REQUEST:
        task = yield fork(authSignInFlow, authData);
        break;
      case SIGNUP_REQUEST:
        const authSignUpFlow = asyncFlow(
          callSignUp,
          signUpResult,
          signUpFail,
          createAuthSignUpEffect(stripe)
        );

        // --------------- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! todo: refactor > move top
        let billing = JSON.parse(window.localStorage.getItem('billing'));
        if (billing) {
          authData.billing = billing;
          //authData.userCustomerId = billing.user.id;
        }
        // ---------------

        task = yield fork(authSignUpFlow, authData);
        break;
      case GOOGLE_LOGIN_REQUEST:
        task = yield fork(authGoogleLoginFlow, { tokenId });
        break;
      case SIGNUP_STACK_SOCIAL_REQUEST:
        task = yield fork(signUpStackSocialFlow, {
          email,
          username: name,
          code,
          password
        });
        break;
      default:
    }

    // fork return a Task object
    const action = yield take([
      LOGOUT,
      SIGNIN_FAIL,
      SIGNUP_FAIL,
      GOOGLE_LOGIN_FAIL,
      COMPLETE_SIGNUP_FAIL,
      FAILED_3D_SECURE,
      SIGNUP_STACK_SOCIAL_FAIL
    ]);
    console.log('endAuth');
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
    yield call(authLogoutFlow);
  }
}

function* forgotPassword({ email }) {
  console.log('forgotPassword', email);
  yield forgotPasswordFlow({ email });
}

function* resetPassword({ password, token }) {
  yield resetPasswordFlow({ password, token });
}

export default function* authSaga() {
  yield fork(sagaErrorBoundaries(watchAuth));
  yield takeLatest(
    FORGOT_PASSWORD_REQUEST,
    sagaErrorBoundaries(forgotPassword)
  );
  yield takeLatest(LOGOUT, sagaErrorBoundaries(authLogoutFlow));
  yield takeLatest(
    COMPLETE_SIGNUP_REQUEST,
    sagaErrorBoundaries(completeAuthSignUpFlow)
  );
  yield takeLatest(RESET_PASSWORD_REQUEST, sagaErrorBoundaries(resetPassword));
}
