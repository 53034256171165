import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, FormControl, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSelectContainerComponentStyles } from './styledComponents';

const useOutlinedInputStyles = makeStyles(theme => ({
  notchedOutline: {
    borderRadius: 25
  },
  disabled: {
    color: theme.palette.grey['500']
  }
}));

function ConverterLimitField({ textLength, limit }) {
  const classes = useSelectContainerComponentStyles();
  const inputStyles = useOutlinedInputStyles();
  return (
    <FormControl fullWidth={true} className={classes.root}>
      <InputLabel
        htmlFor="available-symbols"
        variant="outlined"
        className={classes.inputLabel}
      >
        Symbols
      </InputLabel>

      <OutlinedInput
        name="symbols-field"
        id="available-symbols"
        value={textLength + '/' + limit}
        classes={inputStyles}
        disabled
      />
    </FormControl>
  );
}

ConverterLimitField.propTypes = {
  textLength: PropTypes.number,
  limit: PropTypes.number
};

export default ConverterLimitField;
