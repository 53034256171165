import { takeEvery } from 'redux-saga/effects';

import {
  RESTORE_SUBSCRIPTION_REQUEST,
  restoreSubscriptionResult,
  restoreSubscriptionFail
} from '../../actions/paymentActions';
import { asyncFlow, callRestoreSubscription } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

function* restoreSubscription({
  email,
  restoreSuccessEvent,
  restoreFailEvent
}) {
  const restoreSubscriptionFlow = asyncFlow(
    callRestoreSubscription,
    restoreSubscriptionResult,
    restoreSubscriptionFail,
    restoreSuccessEvent,
    null,
    null,
    restoreFailEvent
  );
  yield restoreSubscriptionFlow({
    email
  });
}

export default function* restoreSubscriptionSaga() {
  yield takeEvery(
    RESTORE_SUBSCRIPTION_REQUEST,
    sagaErrorBoundaries(restoreSubscription)
  );
}
