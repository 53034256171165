import { createSelector } from 'reselect';

const getUser = state => state.auth.user;
const isFreePlan = state => {
  return getUser(state) && state.auth.user.plan === 'free';
};
const planWasPaid = state => getUser(state) && !!state.auth.user.paid;

export const isPaidSelector = createSelector(
  [isFreePlan, planWasPaid],
  (free, paid) => {
    console.log('plan Selector', free, paid, free ? true : paid);
    return free ? true : paid;
  }
);

export const isAuthSelector = createSelector(
  [getUser],
  user => {
    return user && user.token;
  }
);

export const isConfirmedSelector = createSelector(
  [getUser],
  user => {
    return user && user.confirmed;
  }
);

export const isFullAuthSelector = createSelector(
  [isAuthSelector, isPaidSelector, isConfirmedSelector],
  (auth, paid, confirmed) => {
    return auth && paid && confirmed;
  }
);
