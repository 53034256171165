import { takeLatest, call } from 'redux-saga/effects';

import {
  CONVERT_TEXT_REQUEST,
  convertTextResult,
  convertFail
} from '../../actions/converterActions';
import {
  callConvertTextToSpeech,
  asyncFlow,
  callFreeConvertTextToSpeech
} from '../../api';
import converterTaskPollingSaga from './converterTaskPollingSaga';
import { sagaErrorBoundaries } from '../../utils';

const convertTextFlow = asyncFlow(
  callConvertTextToSpeech,
  convertTextResult,
  convertFail
);

const freeConvertTextFlow = asyncFlow(
  callFreeConvertTextToSpeech,
  convertTextResult,
  convertFail
);

function* convertTextToSpeech({
  free,
  text,
  voiceId,
  email,
  username,
  textType,
  lang,
  gender,
  engine
}) {
  console.log('dataVoiceSaga', voiceId);
  let result;
  if (free) {
    result = yield freeConvertTextFlow({
      text,
      voiceId,
      lang,
      gender,
      engine
    });
  } else {
    result = yield convertTextFlow({
      text,
      textType,
      voiceId,
      username,
      lang,
      email,
      gender,
      engine
    });
  }
  if (!result || free || result.task.TaskStatus === 'completed') return;
  console.log('resultForPolling', result);
  yield call(converterTaskPollingSaga.startPolling, result.task.TaskId);
}

export default function* convertTextSaga() {
  yield takeLatest(
    CONVERT_TEXT_REQUEST,
    sagaErrorBoundaries(convertTextToSpeech)
  );
}
