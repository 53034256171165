import { takeEvery } from 'redux-saga/effects';

import {
  GET_SUBSCRIPTION_REQUEST,
  getSubscriptionResult,
  getSubscriptionFail
} from '../../actions/paymentActions';
import { asyncFlow, callGetSubscription } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

const getSubscriptionFlow = asyncFlow(
  callGetSubscription,
  getSubscriptionResult,
  getSubscriptionFail
);

function* getSubscription({ email }) {
  yield getSubscriptionFlow({
    email
  });
}

export default function* getSubscriptionSaga() {
  yield takeEvery(
    GET_SUBSCRIPTION_REQUEST,
    sagaErrorBoundaries(getSubscription)
  );
}
