import {
  PARSE_DOCUMENT_REQUEST,
  PARSE_DOCUMENT_RESULT,
  PARSE_DOCUMENT_FAIL,
  CLEAR_PARSE_DOCUMENT_ERROR,
  DOCUMENT_JOB_POLLING_RESULT,
  START_DOCUMENT_JOB_POLLING,
  STOP_DOCUMENT_JOB_POLLING,
  FAIL_DOCUMENT_JOB_POLLING
} from '../actions/documentActions';
import { getErrorMessage } from '../utils';

const initialState = {
  loading: false,
  error: '',
  parsedText: '',
  setParsedText: () => {}
};

export default function reducer(
  state = initialState,
  { type, loading, parsedText, setParsedText, error }
) {
  switch (type) {
    case PARSE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading
      };
    case PARSE_DOCUMENT_RESULT:
      return {
        ...state,
        parsedText,
        loading
      };
    case PARSE_DOCUMENT_FAIL:
      return {
        ...state,
        loading,
        error: getErrorMessage(error)
      };
    case CLEAR_PARSE_DOCUMENT_ERROR:
      return {
        ...state,
        error
      };
    case START_DOCUMENT_JOB_POLLING:
      return {
        ...state,
        setParsedText,
        loading
      };
    case STOP_DOCUMENT_JOB_POLLING:
      return {
        ...state,
        loading
      };
    case FAIL_DOCUMENT_JOB_POLLING:
      return {
        ...state,
        loading
      };
    case DOCUMENT_JOB_POLLING_RESULT:
      if (parsedText) {
        this.state.setParsedText(parsedText);
      }
      return {
        ...state,
        loading
      };
    default:
      return state;
  }
}
