import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography
} from '@material-ui/core';

import {
  useSelectContainerComponentStyles,
  useSelectComponentStyles,
  StyledOutlinedInput
} from './styledComponents';
import SelectBadge from '../Badge/SelectBadge';

function SelectVoiceField({
  voices,
  value,
  isFieldError,
  displayErrorMessage,
  onFieldChange,
  errors,
  touched,
  free
}) {
  const classes = useSelectContainerComponentStyles();
  const selectStyles = useSelectComponentStyles();
  const selectedVoice = voices.find(voice => voice.Id === value);
  const [selectOpened, openSelect] = useState(false);
  return (
    <FormControl
      fullWidth={true}
      className={classes.root}
      error={isFieldError(errors, touched, 'voice')}
    >
      <InputLabel className={classes.inputLabel} variant="outlined">
        Choose Voice
      </InputLabel>
      {selectedVoice && (
        <SelectBadge
          free={free}
          isActive={true}
          supportedEngines={selectedVoice.SupportedEngines}
        />
      )}
      <Select
        value={value}
        variant="outlined"
        onChange={event => onFieldChange()('voice', event.target.value)}
        onOpen={() => openSelect(true)}
        onClose={() => openSelect(false)}
        classes={selectStyles}
        input={<StyledOutlinedInput name="voice" id="voice-helper" />}
      >
        {voices.map(voice => {
          return (
            <MenuItem value={voice.Id} key={voice.Id}>
              <Typography>
                {voice.Name} ({voice.Gender}, {voice.LanguageName})
              </Typography>
              {voice.Id !== value ? (
                <SelectBadge supportedEngines={voice.SupportedEngines} />
              ) : selectOpened ? (
                <SelectBadge
                  supportedEngines={voice.SupportedEngines}
                  isActiveOpened={selectOpened}
                />
              ) : null}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>
        {displayErrorMessage(errors, touched, 'voice')}
      </FormHelperText>
    </FormControl>
  );
}

SelectVoiceField.propTypes = {
  voices: PropTypes.array,
  value: PropTypes.any,
  isFieldError: PropTypes.func,
  displayErrorMessage: PropTypes.func,
  onFieldChange: PropTypes.func,
  errors: PropTypes.any,
  touched: PropTypes.any,
  free: PropTypes.bool.isRequired
};

export default SelectVoiceField;
