import { spawn } from 'redux-saga/effects';

import convertTextSaga from './convertTextSaga';
import converterHistorySaga from './converterHistorySaga';
import converterTaskPollingSaga from './converterTaskPollingSaga';
import converterGetVoicesSaga from './converterGetVoicesSaga';

export default function* сonverterSaga() {
  yield spawn(convertTextSaga);
  yield spawn(converterHistorySaga);
  yield spawn(converterTaskPollingSaga.getSaga);
  yield spawn(converterGetVoicesSaga);
}
