import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FreeConverterSection from './FreeConverterSection';
import PlanSection from './PlanSection';
import WhyUsSection from './WhyUsSection';
import Footer from '../footer/Footer';
import TopHeroSection from './TopHeroSection';
import TopVoicesSection from './TopVoicesSection';
import { upgradeSubscriptionRequest } from '../../redux/actions/paymentActions';
import {
  clearAuthError,
  clearAuthSuccess
} from '../../redux/actions/authActions';
import Snackbar from '../../ui/Snackbar/Snackbar';
import { isAuthSelector } from '../../selectors/authSelector';

const Home = ({
  user,
  clearAuthError,
  clearAuthSuccess,
  successMessage,
  errorMessage,
  upgradeSubscriptionRequest,
  isAuth
}) => {
  useEffect(() => {
    return () => {
      clearAuthError();
      clearAuthSuccess();
    };
  }, [clearAuthError, clearAuthSuccess]);

  return (
    <>
      <TopHeroSection isAuth={isAuth} />
      <TopVoicesSection />
      {!isAuth && <FreeConverterSection />}
      <PlanSection
        isAuth
        user={user}
        upgradeSubscription={upgradeSubscriptionRequest}
      />
      <WhyUsSection />
      <Footer />
      <Snackbar
        variant="success"
        message={successMessage}
        onClose={clearAuthSuccess}
      />
      <Snackbar
        variant="error"
        message={errorMessage}
        onClose={clearAuthError}
      />
    </>
  );
};

Home.propTypes = {
  user: PropTypes.any,
  upgradeSubscriptionRequest: PropTypes.func,
  clearAuthError: PropTypes.func,
  clearAuthSuccess: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  isAuth: PropTypes.bool
};

export default connect(
  state => {
    console.log('state.support', state.support);
    return {
      user: state.auth.user,
      isAuth: isAuthSelector(state),
      successMessage: state.auth.success,
      errorMessage: state.auth.error
    };
  },
  {
    upgradeSubscriptionRequest,
    clearAuthError,
    clearAuthSuccess
  }
)(Home);
