import {
  WEBSOCKET_CONNECTION_SUCCESS,
  WEBSOCKET_CONNECTION_ERROR,
  WEBSOCKET_DISCONNECT_SUCCESS
} from '../actions/websocketAction';

function getWebsocketErrorMessage(error) {
  console.log(error);
  return error;
}

const initialState = {
  connection: false
};

export default function reducer(
  state = initialState,
  { type, connection, error }
) {
  switch (type) {
    case WEBSOCKET_CONNECTION_SUCCESS:
      return {
        ...state,
        connection
      };
    case WEBSOCKET_CONNECTION_ERROR:
      return {
        ...state,
        connection,
        error: getWebsocketErrorMessage(error)
      };
    case WEBSOCKET_DISCONNECT_SUCCESS:
      return {
        ...state,
        connection
      };
    default:
      return state;
  }
}
