import React from 'react';
import {
  List,
  Drawer,
  ListItem,
  ListItemText,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CreditCard from '@material-ui/icons/CreditCard';
import HelpOutline from '@material-ui/icons/HelpOutline';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';

import ListLink from '../ListItem/ListLink';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: 350
    },
    paper: {
      top: 90,
      width: 350,
      backgroundColor: '#9e9e9e1c'
    }
  };
});

const useListStyles = makeStyles(theme => {
  return {
    root: {
      padding: 0
    },
    titleListItem: {
      padding: theme.spacing(6, 4)
    }
  };
});

const content = [
  { to: '/convert', label: 'Convert', icon: <RecordVoiceOver /> },
  { to: '/profile', label: 'Profile', icon: <AccountCircle /> },
  { to: '/billing', label: 'Billing', icon: <CreditCard /> },
  { to: '/FAQ', label: 'FAQ', icon: <HelpOutline /> },
  { to: '/support', label: 'Support', icon: <EmojiPeople /> }
];
const listTitleTypographyProps = {
  align: 'center',
  component: 'h2',
  variant: 'h5'
};
const PermanentDrawer = ({ children, active, title }) => {
  const classes = useStyles();
  const listClasses = useListStyles();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'), {
    noSsr: true
  });
  return (
    isDesktop && (
      <Drawer variant="permanent" anchor="left" classes={classes}>
        {children ? (
          <List>{children}</List>
        ) : (
          <List className={listClasses.root}>
            {title && (
              <ListItem className={listClasses.titleListItem}>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={listTitleTypographyProps}
                />
              </ListItem>
            )}
            {content.map(item => {
              return (
                <ListLink
                  key={item.to}
                  to={item.to}
                  label={item.label}
                  icon={item.icon}
                  active={item.to === active}
                />
              );
            })}
          </List>
        )}
      </Drawer>
    )
  );
};

PermanentDrawer.propTypes = {
  children: PropTypes.node,
  active: PropTypes.string,
  title: PropTypes.string
};

export default React.memo(PermanentDrawer);
