import React, { useState, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '@werter12/naturaltts-config';

import PlanCard from '../../ui/PlanCard/PlanCard';
import Dialog from '../../ui/Dialog/Dialog';

const PLANS = config.PLANS;

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(12)
    },
    titleSection: {
      position: 'relative',
      overflow: 'hidden',
      padding: theme.spacing(8, 8, 3, 8),
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: theme.palette.grey['600'],
        top: 0
      }
    },
    title: {
      fontWeight: 700
    },
    plansContainer: {
      textAlign: 'center',
      maxWidth: 1300
    },
    subtitle: {
      color: theme.palette.grey['600'],
      maxWidth: 600,
      marginTop: theme.spacing(2),
      textAlign: 'center'
    }
  };
});
const plansKeys = Object.keys(PLANS);

const generatePlanCard = (key, onSubscribePlan, disabled, buttonText) => {
  return (
    <PlanCard
      plan={PLANS[key]}
      onSubscribePlan={onSubscribePlan}
      key={key}
      disabled={disabled}
      buttonText={buttonText}
    />
  );
};

const generateSubscribePlanFunctionOld = (
  key,
  history,
  userPlan,
  openDialog
) => {
  return userPlan
    ? userPlan === 'free'
      ? () => {
          return history.push({
            pathname: '/billing',
            state: { typePlan: PLANS[key].shortName }
          });
        }
      : () => {
          openDialog(PLANS[key].shortName);
        }
    : () => {
        return history.push({
          pathname: '/sign-up',
          state: { typePlan: PLANS[key].shortName }
        });
      };
};

const generateSubscribePlanFunction = (key, history, userPlan, openDialog) => {
  return () => {
    return history.push({
      pathname: '/sign-up',
      state: { typePlan: PLANS[key].shortName }
    });
  };
};

const generatePlanButtonText = (iteratorPlan, userPlan, disabled) => {
  return userPlan === iteratorPlan
    ? 'Current'
    : !userPlan
    ? 'Subscribe'
    : disabled
    ? 'Downgrade'
    : 'Upgrade';
};

const PlanSection = ({ user, history, upgradeSubscription }) => {
  const classes = useStyles();
  const [open, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const openDialog = newPlan => {
    setOpenDialog(true);
    setSelectedPlan(newPlan);
  };
  const { plan: userPlan, email } =
    typeof user === 'object' && user ? user : {};
  console.log('userPlan', userPlan);

  const plansCards = [];
  const currentPlanIndex = plansKeys.findIndex(item => item === userPlan);
  // eslint-disable-next-line no-unused-vars
  for (const key in PLANS) {
    let onSubscribePlan = generateSubscribePlanFunction(
      key,
      history,
      userPlan,
      openDialog
    );
    const disabled =
      plansKeys.findIndex(item => item === key) <= currentPlanIndex;
    const buttonText = generatePlanButtonText(key, userPlan, disabled);
    plansCards.push(
      generatePlanCard(key, onSubscribePlan, disabled, buttonText)
    );
  }
  const dialogTitle = `Are you sure to upgrade on ${selectedPlan}?`;
  const upgradePlan = useCallback(() => {
    upgradeSubscription(email, selectedPlan);
    setOpenDialog(false);
  }, [upgradeSubscription, email, selectedPlan, setOpenDialog]);
  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justify="center"
      id="pricing"
    >
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.titleSection}
      >
        <Typography
          align="center"
          component="h2"
          variant="h4"
          color="textPrimary"
          className={classes.title}
        >
          Pricing
        </Typography>
        <Typography
          variant="subtitle1"
          component="h3"
          className={classes.subtitle}
        >
          Try our advanced subscription plans with premium voices for personal
          and commercial usage.
        </Typography>
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="space-around"
        className={classes.plansContainer}
      >
        {plansCards}
      </Grid>
      <Dialog
        open={open}
        handleClose={closeDialog}
        title={dialogTitle}
        handleAgree={upgradePlan}
      />
    </Grid>
  );
};

PlanSection.propTypes = {
  history: PropTypes.object,
  user: PropTypes.any,
  upgradeSubscription: PropTypes.func.isRequired
};

export default withRouter(PlanSection);
