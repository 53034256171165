import { call } from 'redux-saga/effects';

function commitError(error, ...args) {
  console.log('Saga Error: ', args, error);
}

export function getErrorMessage(error) {
  let message = 'Something went wrong';
  console.log('error', error, error.response);
  if (error.response) {
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
    }
  }
  if (error.data && error.data.message) {
    message = error.data.message;
  }
  return message;
}

export function getAttempt(error) {
  let attempt = 0;
  if (error.response) {
    if (error.response.data && error.response.data.attempt) {
      attempt = error.response.data.attempt;
    }
  }
  return attempt;
}

export const sagaErrorBoundaries = (saga, ...args) =>
  function*(action) {
    try {
      yield call(saga, action, ...args);
    } catch (err) {
      yield call(commitError, err, ...args);
    }
  };

export function* manage3DSecurePayment(stripe, client_secret) {
  const data = yield call(stripe.confirmCardPayment, client_secret);
  console.log('data3D', data);
  return data.error;
}
