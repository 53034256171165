/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Checkbox } from 'formik-material-ui';
import cx from 'classnames'

import CircularLoader from '../../../ui/Loader/CircularLoader';
import BaseButton from '../../../ui/Button/BaseButton';
import Snackbar from '../../../ui/Snackbar/Snackbar';
import Link from '../../../ui/Link/Link';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    textField: {
      height: theme.spacing(18),
    },
    passwordField: {
      display: 'table'
    },
    inputLabel: {
      color: theme.palette.grey['500'],
    },
    forgotPasswordLink: {
      color: theme.palette.grey['600'],
    },
    checkbox: {
      color: theme.palette.grey['300'],
    },
    changePasswordField: {
      marginBottom: theme.spacing(6),
    },
  };
});

const ProfileForm = ({
  loading,
  changePassword,
  handleSubmit,
  clearUpdateUserError,
  updateUserError,
  successMessage,
  clearUpdateUserSuccess,
}) => {
  const classes = useStyles();
  const inputLableProps = {
    shrink: true,
    classes: { root: classes.inputLabel },
  };
  return (
    <Grid
      component="form"
      noValidate
      autoComplete="off"
      className={classes.root}
    >
      <Field
        required
        id="name"
        label="Name"
        name="username"
        variant="outlined"
        InputLabelProps={inputLableProps}
        className={classes.textField}
        margin="normal"
        component={TextField}
      />
      <Field
        required
        id="email"
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        disabled={true}
        InputLabelProps={inputLableProps}
        className={classes.textField}
        margin="normal"
        component={TextField}
      />
      <FormControlLabel
        className={classes.changePasswordField}
        control={
          <Field
            color="primary"
            name="changePassword"
            component={Checkbox}
            className={classes.checkbox}
          />
        }
        label="Change Password"
      />
      <Collapse in={changePassword}>
        <Field
          required
          id="oldPassword"
          label="Old Password"
          name="oldPassword"
          type="password"
          variant="outlined"
          placeholder="******"
          fullWidth={true}
          InputLabelProps={inputLableProps}
          className={classes.textField}
          margin="normal"
          component={TextField}
        />
        <Field
          required
          id="newPassword"
          label="New Password"
          name="newPassword"
          type="password"
          variant="outlined"
          placeholder="******"
          fullWidth={true}
          InputLabelProps={inputLableProps}
          className={cx(classes.textField, classes.passwordField)}
          margin="normal"
          component={TextField}
        />
        <Field
          required
          id="passwordConfirmation"
          label="Confirm Password"
          name="passwordConfirmation"
          type="password"
          variant="outlined"
          placeholder="******"
          fullWidth={true}
          InputLabelProps={inputLableProps}
          className={classes.textField}
          margin="normal"
          component={TextField}
        />
      </Collapse>
      <Grid container direction="column" alignItems="center">
        {loading ? (
          <CircularLoader />
        ) : (
          <BaseButton onClick={handleSubmit}>Update</BaseButton>
        )}
      </Grid>
      <Snackbar
        variant="success"
        message={successMessage}
        onClose={clearUpdateUserSuccess}
      />
      <Snackbar
        variant="error"
        message={updateUserError}
        onClose={clearUpdateUserError}
      />
    </Grid>
  );
};

ProfileForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateUserError: PropTypes.any,
  successMessage: PropTypes.any,
  clearUpdateUserError: PropTypes.func.isRequired,
  clearUpdateUserSuccess: PropTypes.func.isRequired,
};

export default ProfileForm;
