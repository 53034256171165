import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Link from '../Link/Link';

const useStyles = makeStyles(theme => {
  return {
    listItem: ({ active, mobile }) => ({
      background: active ? theme.palette.primary.base : '',
      padding: mobile ? theme.spacing(5, 4) : theme.spacing(4, 4),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& div': {
          color: theme.palette.common.white
        }
      }
    }),
    listItemIcon: ({ active }) => ({
      color: active ? theme.palette.text.secondary : theme.palette.grey['700']
    }),
    listItemText: ({ active }) => ({
      color: active ? theme.palette.text.secondary : ''
    })
  };
});

const ListLink = ({ to, label, icon, handler, action, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <Link to={to} disabled={action}>
      <ListItem onClick={handler} className={classes.listItem}>
        {icon && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}
        <ListItemText primary={label} className={classes.listItemText} />
      </ListItem>
    </Link>
  );
};

ListLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  action: PropTypes.bool,
  icon: PropTypes.element,
  handler: PropTypes.func,
  mobile: PropTypes.bool
};

export default ListLink;
