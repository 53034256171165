import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BasicAudioPlayer from '../../ui/AudioPlayer/BasicAudioPlayer';
import BaseButton from '../../ui/Button/BaseButton';
import { AUDIO_BUCKET } from '../../config/buckets';

const useStyles = makeStyles(theme => {
  return {
    titleSection: {
      position: 'relative',
      padding: theme.spacing(8, 8, 10, 8),
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: '#707070',
        top: 0
      }
    },
    title: {
      fontWeight: 700
    },
    buttonContainer: {
      marginTop: 40
    },
    playersContainer: {
      maxWidth: 1400
    },
    subtitle: {
      color: theme.palette.grey['600'],
      maxWidth: 600,
      marginTop: theme.spacing(2),
      textAlign: 'center'
    }
  };
});
const audioPlayerStyles = makeStyles(theme => {
  return {
    root: {
      margin: theme.spacing(3),
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: '90%'
      },
      [theme.breakpoints.up('md')]: {
        width: 450
      },
      [theme.breakpoints.up('lg')]: {
        width: 600
      }
    }
  };
});

const content = [
  {
    title: 'Joanna (Female)',
    description: 'US English',
    audio: `${AUDIO_BUCKET}/Joanna%2C+Female+-+English%2C+US.mp3`,
    image: ''
  },
  {
    title: 'Camila (Female)',
    description: 'Portuguese Brazil',
    audio: `${AUDIO_BUCKET}/Camila%2C+Female+-+Portuguese.mp3`,
    image: ''
  },
  {
    title: 'Lupe (Female)',
    description: 'Spanish',
    audio: `${AUDIO_BUCKET}/Lupe%2C+Female+-+Spanish.mp3`,
    image: ''
  },
  {
    title: 'Matthew (Male)',
    description: 'US English',
    audio: `${AUDIO_BUCKET}/Matthew%2C+Male+-+English%2C+US.mp3`,
    image: ''
  },
  {
    title: 'Brian (Male)',
    description: 'British English',
    audio: `${AUDIO_BUCKET}/Brian%2C+Male+-+English%2C+GB.mp3`,
    image: ''
  },
  {
    title: 'Emma (Female)',
    description: 'British English',
    audio: `${AUDIO_BUCKET}/Emma%2C+Female+-+English%2C+GB.mp3`,
    image: ''
  }
];

function TopVoicesSection() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'), {
    noSsr: true
  });
  const classes = useStyles();
  const [limit, extendLimit] = useState(0);
  const contentLength = content.length;

  const extendLimitHandler = useCallback(() => {
    if (contentLength > limit) {
      extendLimit(val => val + 2);
    }
  }, [extendLimit, contentLength, limit]);
  useEffect(() => {
    extendLimit(isMobile ? 4 : 6);
  }, [extendLimit, isMobile]);
  const computeVoicesExamples = useMemo(() => {
    const voicesExamples = [];
    for (let i = 0; i < contentLength; i++) {
      if (i >= limit) {
        break;
      }
      let voice = content[i];
      voicesExamples.push(
        <BasicAudioPlayer
          key={voice.title}
          src={voice.audio}
          title={voice.title}
          description={voice.description}
          image={voice.image}
          useStyles={audioPlayerStyles}
          width="auto"
        />
      );
    }
    return voicesExamples;
  }, [contentLength, limit]);

  return (
    <Grid container className={classes.root} justify="center" id="voices">
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.titleSection}
      >
        <Typography
          variant="h4"
          component="h2"
          color="textPrimary"
          className={classes.title}
        >
          Top Voices
        </Typography>
        <Typography
          variant="subtitle1"
          component="h3"
          className={classes.subtitle}
        >
          Listen to the natural voices examples created with our text to speech
          software. More than 61 premium, high-quality voices are available in
          our converter
        </Typography>
      </Grid>
      <Grid
        item
        container
        justify="center"
        className={classes.playersContainer}
      >
        {computeVoicesExamples}
      </Grid>
      {contentLength > limit && (
        <Grid
          item
          container
          justify="center"
          className={classes.buttonContainer}
        >
          <BaseButton variation="light" onClick={extendLimitHandler}>
            More Voices
          </BaseButton>
        </Grid>
      )}
    </Grid>
  );
}

//TopHeroSection.propTypes = {};

export default TopVoicesSection;
