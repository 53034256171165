import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ConverterContainer from '../converter/ConverterContainer';
import { PICTURES_BUCKET } from '../../config/buckets';

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(12),
      height: 1050,
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center'
      }
    },
    innerRoot: {
      maxWidth: 1400
    },
    rootHeroImage: {
      width: 'auto',
      height: '100%',
      top: 0,
      position: 'absolute',
      overflow: 'hidden',
      [theme.breakpoints.up(1600)]: {
        width: '100%',
        height: 'auto'
      }
    },
    heroImage: {
      width: 'auto',
      height: '100%',
      [theme.breakpoints.up(1600)]: {
        width: '100%',
        height: 'auto'
      }
    },
    wavePicture: {
      position: 'absolute',
      [theme.breakpoints.up('md')]: {
        width: '100%'
      },
      height: 220,
      transform: 'scaleY(-1)',
      left: 0,
      top: 0,
      zIndex: 1
    },
    opacityOverlay: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      background: theme.palette.primary.base,
      opacity: 0.8
    },
    fadedOverlay: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 150,
      width: '100%',
      background: 'linear-gradient( to bottom, transparent, white )',
      zIndex: 2
    },
    titleContainer: {
      zIndex: 2,
      marginTop: 0,
      marginBottom: theme.spacing(8),
      '& h6': {
        opacity: 0.7,
        marginTop: theme.spacing(1)
      }
    }
  };
});

export default function FreeConverterSection() {
  const classes = useStyles();
  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      className={classes.root}
      id="free-converter"
    >
      <Grid
        container
        alignContent="center"
        justify="center"
        className={classes.innerRoot}
      >
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography
            align="center"
            component="h2"
            variant="h4"
            color="textSecondary"
          >
            Try for FREE
          </Typography>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            1 free attempt per day
          </Typography>
        </Grid>
        <ConverterContainer free={true} />
        <picture className={classes.rootHeroImage}>
          <source
            srcSet={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d2000x1264.jpg`}
            media="(min-width: 1919px)"
          />
          <source
            srcSet={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d1440x910.jpg`}
            media="(min-width: 1440px) and (max-width: 1919px)"
          />
          <source
            srcSet={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d1280x809.jpg`}
            media="(min-width: 1280px) and (max-width: 1439px)"
          />
          <source
            srcSet={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d768x485.jpg`}
            media="(min-width: 480px) and (max-width: 1279px)"
          />
          <source
            srcSet={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d887x561.jpg`}
            media="(max-width: 479px)"
          />
          <img
            className={classes.heroImage}
            src={`${PICTURES_BUCKET}/homepage/manStandWithPhone.d1440x910.jpg`}
            alt="Free Converter Naturaltts"
          />
        </picture>
        <img
          className={classes.wavePicture}
          src={`${PICTURES_BUCKET}/wave_1.png`}
          alt="Free Converter Wave"
        />
        <div className={classes.fadedOverlay} />
        <div className={classes.opacityOverlay} />
      </Grid>
    </Grid>
  );
}
