import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Link, Link as OutsideLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';

import AnchorLink from '../../ui/Link/AnchorLink';
import { PICTURES_BUCKET } from '../../config/buckets';

const useStyles = makeStyles(theme => {
  return {
    root: {
      height: 600,
      position: 'relative',
      paddingTop: 200,
      padding: '200px 25px 0 25px',
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        padding: '300px 0 0 0',
        height: 500
      }
      // '&::before': {
      //   content: "''",
      //   position: 'absolute',
      //   height: '100%',
      //   width: '100%',
      //   display: 'block',
      //   left: 0,
      //   top: 0,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundImage: `url(${PICTURES_BUCKET}/manWithLaptop.jpg)`,
      //   backgroundColor: 'white'
      // }
      //   [theme.breakpoints.up('sm')]: {
      //     justifyContent: 'center'
      //   }
    },
    rootHeroImage: {
      width: 'auto',
      height: '100%',
      top: 0,
      position: 'absolute',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        height: 'auto'
      }
    },
    heroImage: {
      width: 'auto',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        height: 'auto'
      }
    },
    opacityOverlay: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      background: '#14161A',
      opacity: 0.8
    },
    waveLayer: {
      position: 'absolute',
      height: 200,
      [theme.breakpoints.up('md')]: {
        height: 300,
        width: '100%'
      },
      left: 0,
      top: 0,
      zIndex: 1
    },
    wrapper: {
      maxWidth: 1000,
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap'
      },
      '& a': {
        margin: '3px 0'
      }
    },
    item: {
      zIndex: 3,
      margin: '0 20px'
    },
    firstContainer: {
      flex: '1 1 auto',
      minWidth: 300,
      position: 'relative',
      '&::after': {
        content: "''",
        display: 'block',
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        },
        position: 'absolute',
        height: 1,
        backgroundColor: theme.palette.common.white,
        opacity: 0.5,
        bottom: 30,
        left: 0,
        right: 0
      },
      [theme.breakpoints.up('md')]: {
        flex: '1 1 auto'
      }
    },
    secondContainer: {
      flex: '1 1',
      [theme.breakpoints.up('md')]: {
        flex: '1 1 auto'
      }
    },
    thirdContainer: {
      flex: '1 1',
      [theme.breakpoints.up('md')]: {
        flex: '1 1 auto'
      }
    },
    footerLinkStyles: {
      color: '#FFFFFF',
      textDecoration: 'underline',
      fontSize: '0.875rem',
      opacity: '0.5'
    },
    logoImage: {
      width: 220
    },
    title: {
      marginBottom: 25,
      fontWeight: 700
    },
    description: {
      marginTop: 25,
      opacity: 0.5
    }
  };
});

const useFooterLinkStyles = makeStyles({
  root: {
    opacity: 0.5
  }
});

const FooterLink = ({ children, to }) => {
  const classes = useFooterLinkStyles();
  return (
    <Link
      component={RouterLink}
      TypographyClasses={classes}
      underline="always"
      to={to}
      variant="body2"
      color="textSecondary"
    >
      {children}
    </Link>
  );
};

FooterLink.propTypes = {
  to: PropTypes.string.isRequired
};

const FooterOutsideLink = ({ children, to }) => {
  const classes = useFooterLinkStyles();
  return (
    <OutsideLink
      TypographyClasses={classes}
      underline="always"
      href={to}
      variant="body2"
      color="textSecondary"
    >
      {children}
    </OutsideLink>
  );
};

FooterOutsideLink.propTypes = {
  to: PropTypes.string.isRequired
};

const Footer = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const triggerImageLoaded = useCallback(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);
  const classes = useStyles();
  return (
    <Grid container className={classes.root} justify="center">
      <Grid item container className={classes.wrapper}>
        <Grid item className={cx(classes.item, classes.firstContainer)}>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.title}
          >
            Naturaltts
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            Naturaltts is the best text to speech converter with natural voices.
            Free mp3 download. Copyright ©. Naturaltts.com all right reserved.
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          className={cx(classes.item, classes.secondContainer)}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.title}
          >
            Policies
          </Typography>
          <FooterLink to="/refund">Refund</FooterLink>
          <FooterLink to="/terms">Terms</FooterLink>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={cx(classes.item, classes.thirdContainer)}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.title}
          >
            About
          </Typography>
          <FooterLink to="/faq">FAQ</FooterLink>
          <AnchorLink to="/pricing" className={classes.footerLinkStyles}>
            Pricing
          </AnchorLink>
          <FooterOutsideLink to="https://naturaltts.firstpromoter.com/signup/5607">
            Affiliate Program
          </FooterOutsideLink>
          <FooterLink to="/contact-us">Contact Us</FooterLink>
        </Grid>
      </Grid>

      <picture onLoad={triggerImageLoaded} className={classes.rootHeroImage}>
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithLaptop.d2000x1002.jpg`}
          media="(min-width: 1919px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithLaptop.d1440x721.jpg`}
          media="(min-width: 1440px) and (max-width: 1919px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithLaptop.d1280x641.jpg`}
          media="(min-width: 1280px) and (max-width: 1439px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithLaptop.d768x385.jpg`}
          media="(min-width: 480px) and (max-width: 1279px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithLaptop.d887x700.jpg`}
          media="(max-width: 479px)"
        />
        <img
          className={classes.heroImage}
          src={`${PICTURES_BUCKET}/homepage/manWithLaptop.d1440x721.jpg`}
          alt="Naturaltts Footer"
        />
      </picture>
      <img
        className={classes.waveLayer}
        src={`${PICTURES_BUCKET}/footerWave.png`}
        alt="Footer Wave"
      />
      {imageLoaded && <div className={classes.opacityOverlay} />}
    </Grid>
  );
};

Footer.propTypes = {};

export default Footer;
