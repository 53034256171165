/* eslint-disable */
import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isAuthSelector } from '../../selectors/authSelector';

const authRoutes = ['/sign-in', '/sign-up', '/forgot-password', '/stack-social'];

function AuthRoute({ component: Component, isAuth, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        console.log('authRoute', !isAuth, authRoutes.includes(props.location.pathname));
        return !isAuth && authRoutes.includes(props.location.pathname) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

AuthRoute.propTypes = {
  component: PropTypes.any,
  isAuth: PropTypes.any
};

function mapStateToProps(state) {
  return {
    isAuth: isAuthSelector(state)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(AuthRoute)
);
