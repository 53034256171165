import {
  startDocumentJobPolling,
  START_DOCUMENT_JOB_POLLING,
  STOP_DOCUMENT_JOB_POLLING,
  FAIL_DOCUMENT_JOB_POLLING,
  failDocumentJobPolling,
  documentJobPollingResult,
  stopDocumentJobPolling
} from '../../actions/documentActions';
import { callGetDocumentJob } from '../../api';
import PollingSaga from '../generalSaga/PollingSaga';

const actions = {
  STOP_POLLING: STOP_DOCUMENT_JOB_POLLING,
  START_POLLING: START_DOCUMENT_JOB_POLLING,
  FAIL_POLLING: FAIL_DOCUMENT_JOB_POLLING
};
const actionCreatorts = {
  startPolling: startDocumentJobPolling,
  failPolling: failDocumentJobPolling,
  stopPolling: stopDocumentJobPolling,
  pollingResult: documentJobPollingResult
};
const options = { delay: 3000, makeRequest: callGetDocumentJob };

const documentJobPollingSaga = new PollingSaga(
  actions,
  actionCreatorts,
  options,
  {
    entity: 'job'
  }
);
export default documentJobPollingSaga;
