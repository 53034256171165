import { takeEvery, call } from 'redux-saga/effects';

import {
  UPDATE_CARD_REQUEST,
  updateCardResult,
  updateCardFail
} from '../../actions/paymentActions';
import { callUpdateCard, asyncFlow } from '../../api';
import { sagaErrorBoundaries } from '../../utils';
import Storage from '../../../storage';

function* updateCardPostEffect({
  charsLimit,
  paid,
  subscriptionEnd,
  onlyCardUpdate
}) {
  if (!onlyCardUpdate) {
    const user = yield call(Storage.getItem, 'NATURALTTS_USER', true);
    const updatedUser = {
      ...user,
      charsLimit,
      paid,
      subscriptionEnd
    };
    yield call(Storage.setItem, 'NATURALTTS_USER', updatedUser, true);
  }
}

const updateCardFlow = asyncFlow(
  callUpdateCard,
  updateCardResult,
  updateCardFail,
  updateCardPostEffect
);

export default function* updateCardSaga() {
  yield takeEvery(UPDATE_CARD_REQUEST, sagaErrorBoundaries(updateCardFlow));
}
