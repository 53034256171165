import React from 'react';
import { Grid, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import BroadAudioPlayer from '../../ui/AudioPlayer/BroadAudioPlayer';
import BasicAudioPlayer from '../../ui/AudioPlayer/BasicAudioPlayer';

const useStyles = makeStyles(theme => {
  return {
    broadAudioPlayerContainer: {
      zIndex: 2,
      marginTop: theme.spacing(-2),
      marginLeft: 20,
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(-4)
      }
    },
    basicAudioPlayerContainer: {
      marginBottom: theme.spacing(4)
    }
  };
});

const ConverterPlayer = ({
  audioTask,
  displayPlayer,
  containerGrid,
  audioIsReady
}) => {
  const classes = useStyles();
  const audioPlayerTitle = audioIsReady
    ? `${audioTask.VoiceId} ${audioTask.Gender}`
    : '';

  return displayPlayer ? (
    <Grid
      className={classes.broadAudioPlayerContainer}
      item
      xs={12}
      sm={10}
      lg={8}
      xl={7}
    >
      <Collapse in={audioIsReady} timeout={600} mountOnEnter unmountOnExit>
        <BroadAudioPlayer
          src={audioTask.OutputUri || ''}
          title={audioPlayerTitle}
          description={audioTask.Lang}
        />
      </Collapse>
    </Grid>
  ) : (
    <Grid item {...containerGrid} className={classes.basicAudioPlayerContainer}>
      <Collapse in={audioIsReady} timeout={600} mountOnEnter unmountOnExit>
        <BasicAudioPlayer
          src={audioTask.OutputUri || ''}
          title={audioPlayerTitle}
          description={audioTask.Lang}
          download={true}
          volumeControl={true}
          textControl={true}
          bottomControlPanel={true}
          convertedText={audioTask.Text}
          variation="primary"
          width="100%"
        />
      </Collapse>
    </Grid>
  );
};

ConverterPlayer.propTypes = {
  audioIsReady: PropTypes.bool.isRequired,
  displayPlayer: PropTypes.bool.isRequired,
  containerGrid: PropTypes.object,
  audioTask: PropTypes.any
};

export default ConverterPlayer;
