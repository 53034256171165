import moment from 'moment';

import {
  CONVERT_TEXT_FAIL,
  CONVERT_TEXT_RESULT,
  CONVERT_TEXT_REQUEST,
  CONVERTER_HISTORY_REQUEST,
  CONVERTER_HISTORY_RESULT,
  CONVERTER_HISTORY_FAIL,
  CONVERTER_TASK_POLLING_RESULT,
  CONVERTER_RESET_AUDIO_REQUEST,
  CONVERTER_GET_VOICES_REQUEST,
  CONVERTER_GET_VOICES_RESULT,
  CONVERTER_GET_VOICES_FAIL,
  CHANGE_VOICE_LANGUAGE,
  FREE_VOICES_FLAG,
  CLEAR_CONVERTER_TEXT_ERROR,
  START_CONVERTER_TASK_POLLING,
  STOP_CONVERTER_TASK_POLLING,
  FAIL_CONVERTER_TASK_POLLING
} from '../actions/converterActions';
import { getErrorMessage, getAttempt } from '../utils';

const initialState = {
  loading: false,
  freeVoicesFlag: false,
  converterHistory: [],
  audioTask: {},
  voices: [],
  voiceLanguage: 'en-US',
  playing: null,
  converterError: null,
  lastTaskKey: null,
  hasMore: false,
  attempt: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CONVERT_TEXT_REQUEST:
      return {
        ...state,
        loading: action.loading,
        converterError: null
      };
    case CONVERTER_HISTORY_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case CONVERTER_HISTORY_RESULT:
      console.log('converterHistory', action.tasks);
      const converterHistoryLength = state.converterHistory.length > 0;
      const actionTasksLength =
        Array.isArray(action.tasks) && action.tasks.length > 0;
      const converterHistoryIds = converterHistoryLength
        ? state.converterHistory.map(audioTask => audioTask.id)
        : [];
      const filteredTasks = actionTasksLength
        ? action.tasks.filter(
            audioTask => !converterHistoryIds.includes(audioTask.id)
          )
        : [];
      let converterHistory = [];
      console.log('state.converterHistory', state.converterHistory);
      console.log('filteredTasks', filteredTasks);
      if (
        filteredTasks.length > 0 &&
        converterHistoryLength &&
        moment(state.converterHistory[0].createdAt).isAfter(
          filteredTasks[0].createdAt
        )
      ) {
        converterHistory = [...state.converterHistory, ...filteredTasks];
      } else {
        converterHistory = [...filteredTasks, ...state.converterHistory];
      }
      return {
        ...state,
        converterHistory,
        loading: action.loading,
        hasMore: action.hasMore
      };
    case CONVERTER_HISTORY_FAIL:
      return {
        ...state,
        loading: action.loading
      };
    case CONVERT_TEXT_RESULT:
      console.log('reducerCurrent', action.audioTask);
      return {
        ...state,
        loading: action.loading,
        audioTask: action.audioTask
      };
    case CONVERT_TEXT_FAIL:
      console.log('CONVERT_TEXT_FAIL', action.converterError.response.data);
      return {
        ...state,
        loading: action.loading,
        converterError: getErrorMessage(action.converterError),
        attempt: getAttempt(action.converterError)
      };
    case CLEAR_CONVERTER_TEXT_ERROR:
      return {
        ...state,
        converterError: action.converterError
      };
    case CONVERTER_TASK_POLLING_RESULT:
      return {
        ...state,
        audioTask: { ...state.audioTask, ...action.audioTask }
      };
    case CONVERTER_RESET_AUDIO_REQUEST:
      return {
        ...state,
        audioTask: {}
      };
    case CONVERTER_GET_VOICES_REQUEST:
      return {
        ...state,
        voicesLoading: action.voicesLoading
      };
    case CONVERTER_GET_VOICES_RESULT:
      return {
        ...state,
        voicesLoading: action.voicesLoading,
        voices: action.voices
      };
    case CONVERTER_GET_VOICES_FAIL:
      return {
        ...state,
        voicesLoading: action.voicesLoading
      };
    case CHANGE_VOICE_LANGUAGE:
      return {
        ...state,
        voiceLanguage: action.voiceLanguage
      };
    case FREE_VOICES_FLAG:
      return {
        ...state,
        freeVoicesFlag: action.freeVoicesFlag
      };
    case START_CONVERTER_TASK_POLLING:
      return {
        ...state,
        loading: action.loading
      };
    case STOP_CONVERTER_TASK_POLLING:
      return {
        ...state,
        loading: action.loading
      };
    case FAIL_CONVERTER_TASK_POLLING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
}
