export const WEBSOCKET_CONNECTION_SUCCESS = 'WEBSOCKET_CONNECTION_SUCCESS';
export const WEBSOCKET_CONNECTION_ERROR = 'WEBSOCKET_CONNECTION_ERROR';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WEBSOCKET_DISCONNECT_SUCCESS = 'WEBSOCKET_DISCONNECT_SUCCESS';
export const WEBSOCKET_GET_MESSAGE = 'WEBSOCKET_GET_MESSAGE';

export function websocketConnectionSuccess() {
  return {
    type: WEBSOCKET_CONNECTION_SUCCESS,
    connection: true
  };
}

export function websocketConnectionError(error) {
  return {
    type: WEBSOCKET_CONNECTION_ERROR,
    connection: false,
    error
  };
}

export function websocketDisconnectSuccess() {
  return {
    type: WEBSOCKET_DISCONNECT_SUCCESS,
    connection: false
  };
}

export function websocketGetMessage(message) {
  return {
    type: WEBSOCKET_GET_MESSAGE,
    message
  };
}
