import React from 'react';
//import PropTypes from 'prop-types';
import { Grid, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { PICTURES_BUCKET } from '../../config/buckets';

const useStyles = makeStyles(theme => {
  return {
    root: {
      height: 700,
      margin: theme.spacing(18, 10, 0, 8),
      width: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(18),
        width: '100%',
        height: 900
      },
      [theme.breakpoints.up('md')]: {
        overflow: 'hidden',
        height: 950,
        maxWidth: 1200
      },
      [theme.breakpoints.up('lg')]: {
        margin: '0 auto',
        marginTop: theme.spacing(18)
      }
    },
    titleSection: {
      position: 'relative',
      padding: theme.spacing(8, 8, 10, 0),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4)
      },
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: theme.palette.secondary.main,
        top: 0,
        left: 0,
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(4)
        }
      }
    },
    contentContainer: {
      // alignItems: 'flex-start',
      // height: '100%',
      // [theme.breakpoints.up('md')]: {
      //   alignItems: 'flex-start'
      // }
    },
    image: {
      width: 280,
      height: 200,
      position: 'relative',
      borderRadius: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        height: 648
      },
      overflow: 'hidden',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        bottom: 0,
        backgroundImage: `url(${PICTURES_BUCKET}/manSitWithPhone.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50%'
      },
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: '-20px',
        left: 15,
        width: 250,
        height: 40,
        background: theme.palette.primary.base,
        borderRadius: theme.spacing(8),
        zIndex: '-1'
      }
    },
    descriptionItem: {
      height: 150,
      padding: theme.spacing(1, 0),
      width: '100%'
    },
    point: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 100,
      width: 10,
      height: 10,
      marginTop: 12,
      marginRight: 16,
      flex: '0 0 auto'
    },
    listItem: {},
    listItemContent: {
      flex: '1 1',
      position: 'relative',
      paddingBottom: 20,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 40
      }
    },
    listItemTitle: {
      fontWeight: 'bold',
      marginBottom: 4
    },
    divider: {
      bottom: 0,
      left: 0,
      right: 0
    }
  };
});

// const useCarouselStyles = makeStyles(theme => {
//   return {};
// });

const content = [
  {
    title: '61 Natural Voices',
    text:
      'An incredible amount of real-sounding natural voices that are presented in our text to speech software.'
  },
  {
    title: 'OCR Technology for Commercials',
    text:
      'We provide reading from the scanned documents and other files for our Commercial Plan customers.'
  },
  {
    title: 'SSML Support',
    text:
      'Simply switching the special SSML tab, you can easily customize and control aspects of speech such as pronunciation, volume, and speech rate.'
  },
  {
    title: 'IVR Voice Generator',
    text: 'Expand your IVR system using natural-sounding realistic voices.'
  },
  {
    title: 'Commercial Benefits',
    text:
      'While using the Commercial Plan you are eligible to redistribute converted audio files.'
  },
  {
    title: 'Youtube Videos',
    text:
      'Huge opportunities for influencers. Voiceover your Youtube videos, broadcasts or any public announcements with our natural voices.'
  },
  {
    title: 'Download audio files',
    text: 'Download converted files in the .mp3 format'
  }
  // {
  //   title: 'Documents',
  //   text:
  //     'Our text to speech software supports uploading .pdf, .docx, .txt and other popular document formats.'
  // },
  // {
  //   title: 'Text To Speech',
  //   text:
  //     'Convert any document or text into natural speech voices and generate thousands of audio files at once.'
  // }
];

const WhyUsSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classes.root}
      id="why-us"
    >
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={5}
        alignItems="center"
        justify="center"
        className={classes.itemContainer}
      >
        <div className={classes.image} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={7}
        alignItems="flex-start"
        className={classes.contentContainer}
      >
        <Grid className={classes.titleSection}>
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            className={classes.title}
          >
            Why NaturalTTS is <b>the best text to speech software</b>
          </Typography>
        </Grid>
        <Grid container spacing={isMobile ? 4 : 8} className={classes.list}>
          {content.map(({ title, text }) => {
            return (
              <Grid
                item
                container
                className={classes.listItem}
                xs={12}
                md={6}
                key={title.slice(0, 4)}
              >
                <Grid item className={classes.point} />
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.listItemContent}
                >
                  <Typography variant="h6" className={classes.listItemTitle}>
                    {title}
                  </Typography>
                  <Typography variant="body1">{text}</Typography>
                  <Divider absolute className={classes.divider} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

WhyUsSection.propTypes = {};

export default WhyUsSection;
