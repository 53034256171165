import { spawn } from 'redux-saga/effects';

//import addSubscriptionSaga from './addSubscriptionSaga';
import updateCardSaga from './updateCardSaga';
import cancelSubscriptionSaga from './cancelSubscription';
import getSubscriptionSaga from './getSubscription';
import restoreSubscriptionSaga from './restoreSubscription';
import extendPackageLimit from './extendPackageLimit';
import upgradeSubscription from './upgradeSubscription';
import applyDiscountSaga from './applyDiscountSaga';

export default function* paymentSaga() {
  //yield spawn(addSubscriptionSaga);
  yield spawn(updateCardSaga);
  yield spawn(cancelSubscriptionSaga);
  yield spawn(getSubscriptionSaga);
  yield spawn(restoreSubscriptionSaga);
  yield spawn(extendPackageLimit);
  yield spawn(upgradeSubscription);
  yield spawn(applyDiscountSaga);
}
