import config from '@werter12/naturaltts-config';

const PLANS = config.PLANS;

export default function inlineGuard(plan, permissions) {
  if (!PLANS[plan]) {
    return false;
  }
  return Array.isArray(permissions)
    ? permissions.every(permission => {
        return PLANS[plan].permissions[permission];
      })
    : PLANS[plan].permissions[permissions];
}
