import { takeLatest } from 'redux-saga/effects';

import {
  SUPPORT_QUESTION_REQUEST,
  supportQuestionResult,
  supportQuestionFail
} from '../actions/supportActions';
import { callSupportQuestion, asyncFlow } from '../api';
import { sagaErrorBoundaries } from '../utils';

const supportQuestionFlow = asyncFlow(
  callSupportQuestion,
  supportQuestionResult,
  supportQuestionFail
);

function* sendQuestion({ question }) {
  yield supportQuestionFlow({ question });
}

export default function* userSaga() {
  yield takeLatest(SUPPORT_QUESTION_REQUEST, sagaErrorBoundaries(sendQuestion));
}
