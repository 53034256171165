import React from 'react';

import { EndMessageComponent } from '../utils/helperComponents';

export function converterHistoryConstrain(hasMore, limit) {
  return limit
    ? {
        hasMore: false,
        endMessage: (
          <EndMessageComponent message="Your history is limited to 10 audio items since you has a free plan. Upgrade plan in order to get unlimited history" />
        )
      }
    : {
        hasMore: hasMore,
        endMessage: <EndMessageComponent message="Yay! You have seen it all" />
      };
}
