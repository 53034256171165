import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem as RawListItem,
  ListItemText
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import BaseButton from '../Button/BaseButton';

const useStyles = makeStyles(theme => {
  return {
    root: {
      border: `1px solid ${theme.palette.grey['200']}`,
      borderRadius: 20,
      maxWidth: 380,
      height: 450,
      padding: '35px 25px',
      position: 'relative',
      textAlign: 'left',
      margin: '20px 15px',
      [theme.breakpoints.up('sm')]: {
        padding: '30px 40px'
      }
    },
    planTypeContainer: {
      marginBottom: '5px'
    },
    title: {
      fontWeight: 700
    },
    priceTitle: plan => ({
      color: !plan.live ? theme.palette.action.disabled : 'inherit'
    }),
    buttonContainer: {
      position: 'absolute',
      bottom: -20,
      alignSelf: 'center'
    }
  };
});

const ListItem = withStyles({
  root: {
    padding: '2px 0'
  }
})(RawListItem);

const listTypographyProps = {
  variant: 'body2'
};

const PlanCard = ({
  plan,
  onSubscribePlan,
  disabled = 'false',
  buttonText = 'Subscribe'
}) => {
  const classes = useStyles(plan);

  return (
    <Grid
      item
      container
      justify="center"
      direction="column"
      xs={10}
      sm={6}
      md={4}
      lg={4}
      className={classes.root}
    >
      <Box className={classes.planTypeContainer}>
        <Typography
          variant="h5"
          component="h3"
          color="textPrimary"
          className={cx(classes.title, classes.priceTitle)}
        >
          {plan.longName}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h4"
          component="h4"
          color="secondary"
          className={classes.title}
        >
          {plan.live
            ? plan.permissions.price > 0
              ? `${plan.permissions.price}/month`
              : 'FREE'
            : 'Coming Soon'}
        </Typography>
      </Box>
      <List>
        <ListItem disabled={!plan.live}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.usage}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.permissions.characters} characters
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.voicesPlan}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.permissions.languages}
            {plan.shortName !== 'commercial' ? ' Languages' : ''}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.downloadsLabel}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live || !plan.permissions.ssml}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.ssmlLabel}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live || !plan.permissions.history}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.historyLabel}
          </ListItemText>
        </ListItem>
        <ListItem disabled={!plan.live || !plan.permissions.upload}>
          <ListItemText primaryTypographyProps={listTypographyProps}>
            {plan.uploadsLabel}
          </ListItemText>
        </ListItem>
      </List>
      <Box className={classes.buttonContainer}>
        <BaseButton
          variation="light"
          uppercase={true}
          onClick={onSubscribePlan}
          disabled={!plan.live || disabled}
        >
          {buttonText}
        </BaseButton>
      </Box>
    </Grid>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
  onSubscribePlan: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool
};

export default PlanCard;
