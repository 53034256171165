import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: 130,
      maxWidth: 1200,
      margin: 'auto'
    },
    title: {
      position: 'center',
      padding: theme.spacing(8, 8, 10, 12),
      width: '100%',
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: theme.palette.grey['600'],
        top: 0
      }
    },
    text: {
      padding: theme.spacing(2, 8, 10, 12)
    }
  };
});

const TextSection = ({ title, children, section }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      alignContent="flex-start"
      wrap="wrap"
      className={classes.root}
      id={section}
    >
      <Typography
        variant="h4"
        component="h2"
        color="textPrimary"
        align="center"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography align="left" className={classes.text} component="div">
        {children}
      </Typography>
    </Grid>
  );
};

TextSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  section: PropTypes.string
};

export default TextSection;
