import { useState, useCallback } from 'react';

export default function useDrawer() {
  const [open, setDrawerOpened] = useState(false);
  const toggleDrawer = useCallback(() => setDrawerOpened(val => !val), [
    setDrawerOpened
  ]);
  const handleDrawerOpen = useCallback(() => setDrawerOpened(true), [
    setDrawerOpened
  ]);
  const handleDrawerClose = useCallback(() => {
    setDrawerOpened(false);
  }, [setDrawerOpened]);
  return { open, toggleDrawer, handleDrawerClose, handleDrawerOpen };
}
