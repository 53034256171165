import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConverterContainer from './ConverterContainer';
import ConverterHistory from './ConverterHistory';
import PermanentDrawer from '../../ui/Drawer/PermanentDrawer';
import ComponentGuard from '../auth/Guards/ComponentGuard';

const useStyles = makeStyles(theme => {
  return {
    root: {
      flex: '1 1',
      marginTop: 90,
      paddingTop: theme.spacing(8),
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    titleContainer: {
      zIndex: 2,
      marginTop: 0,
      marginBottom: theme.spacing(8),
      '& h6': {
        opacity: 0.7,
        marginTop: theme.spacing(1)
      }
    }
  };
});
const containerGrid = {
  xs: 12,
  sm: 10,
  md: 11,
  lg: 9,
  xl: 8
};
function ConverterPage({ username }) {
  const classes = useStyles();
  return (
    <Grid container>
      <PermanentDrawer active="/convert" title={username} />
      <Grid container item className={classes.root} id="converter">
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography
            align="center"
            component="h2"
            variant="h4"
            color="textPrimary"
          >
            Text-to-Speech Converter
          </Typography>
        </Grid>
        <ConverterContainer
          containerGrid={containerGrid}
          displayPlayer={false}
        />
        <ComponentGuard permissions="history">
          {permissions => (
            <ConverterHistory
              containerGrid={containerGrid}
              limit={permissions.history.limit}
            />
          )}
        </ComponentGuard>
      </Grid>
    </Grid>
  );
}

ConverterPage.propTypes = {
  username: PropTypes.string
};

export default connect(state => {
  return {
    username: state.auth.user.username
  };
})(ConverterPage);
