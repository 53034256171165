import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PASSWORD_REGEX, USERNAME_REGEX } from 'naturaltts-validation';

import {
  updateUserRequest,
  clearAuthError,
  clearAuthSuccess
} from '../../../redux/actions/authActions';
import FormPage from '../../../ui/FormPage/FormPage';
import ProfileForm from './ProfileForm';
import PermanentDrawer from '../../../ui/Drawer/PermanentDrawer';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      flex: '1 1 auto'
    },
    innerFormWrapper: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: 700
      }
    },
    formWrapper: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 60
      }
    }
  };
});

const Profile = ({
  updateUserError,
  loading,
  handleSubmit,
  successMessage,
  clearAuthError,
  clearAuthSuccess,
  setFieldValue,
  user,
  values
}) => {
  const classes = useStyles();
  const profileFormProps = {
    updateUserError,
    clearUpdateUserError: clearAuthError,
    clearUpdateUserSuccess: clearAuthSuccess,
    changePassword: values.changePassword,
    loading,
    handleSubmit,
    successMessage
  };

  useEffect(() => {
    if (successMessage) {
      setFieldValue('oldPassword', '');
      setFieldValue('newPassword', '');
      setFieldValue('passwordConfirmation', '');
    }
  }, [successMessage, setFieldValue]);
  return (
    <Grid container>
      <PermanentDrawer active="/profile" title={user.username} />
      <Grid className={classes.formContainer} item>
        <FormPage
          title="Profile"
          md={6}
          lg={4}
          wrapperClassname={classes.formWrapper}
          innerWrapperClassName={classes.innerFormWrapper}
        >
          <ProfileForm {...profileFormProps} />
        </FormPage>
      </Grid>
    </Grid>
  );
};

const ProfileWithFormik = withFormik({
  mapPropsToValues: ({ user }) => ({
    username: user.username,
    email: user.email,
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
    changePassword: false
  }),
  handleSubmit: (values, { props: { updateUserRequest }, resetForm }) => {
    updateUserRequest(values);
    resetForm(values);
  },
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .matches(USERNAME_REGEX, 'Name is not valid')
      .required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    changePassword: Yup.boolean(),
    oldPassword: Yup.string().when('changePassword', {
      is: true,
      then: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(
          PASSWORD_REGEX,
          'Password should have at least one letter, one number and one special character'
        )
        .required('Required'),
      otherwise: Yup.string()
    }),
    newPassword: Yup.string().when('changePassword', {
      is: true,
      then: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(
          PASSWORD_REGEX,
          'Password should have at least one letter, one number and one special character'
        )
        .required('Required'),
      otherwise: Yup.string()
    }),
    passwordConfirmation: Yup.string().when('changePassword', {
      is: true,
      then: Yup.string()
        .required('Required')
        .test('match', 'Password do not match', function(password) {
          return password === this.options.parent.newPassword;
        }),
      otherwise: Yup.string()
    })
  })
})(Profile);

Profile.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  clearAuthSuccess: PropTypes.func.isRequired,
  updateUserError: PropTypes.any,
  successMessage: PropTypes.any,
  values: PropTypes.any,
  user: PropTypes.any
};

export default connect(
  state => {
    return {
      user: state.auth.user,
      loading: state.auth.loading,
      updateUserError: state.auth.error,
      successMessage: state.auth.success
    };
  },
  { updateUserRequest, clearAuthError, clearAuthSuccess }
)(ProfileWithFormik);
