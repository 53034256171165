import { takeLatest } from 'redux-saga/effects';

import {
  CONVERTER_HISTORY_REQUEST,
  converterHistoryResult,
  converterHistoryFail
} from '../../actions/converterActions';
import { callGetConverterHistory, asyncFlow } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

const convertHistoryFlow = asyncFlow(
  callGetConverterHistory,
  converterHistoryResult,
  converterHistoryFail
);

function* converterHistory({ email, limit, lte }) {
  console.log('converterHistorySaga', email);
  yield convertHistoryFlow({
    email,
    limit,
    lte
  });
}

export default function* converterHistorySaga() {
  yield takeLatest(
    CONVERTER_HISTORY_REQUEST,
    sagaErrorBoundaries(converterHistory)
  );
}
