import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Box } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CreditCard from '@material-ui/icons/CreditCard';
import HelpOutline from '@material-ui/icons/HelpOutline';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import Home from '@material-ui/icons/Home';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import MobileFriendly from '@material-ui/icons/MobileFriendly';

import ListLink from '../../ui/ListItem/ListLink';
import TopNavbar from '../../ui/TopNavbar/TopNavbar';
import SideDrawer from '../../ui/Drawer/SideDrawer';
import { logout } from '../../redux/actions/authActions';
import { isAuthSelector } from '../../selectors/authSelector';
import useDrawer from '../../hooks/useDrawer';

const Navigation = ({ location }) => {
  const isAuth = useSelector(isAuthSelector);
  const dispatch = useDispatch();
  const dispatchLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const content = isAuth
    ? [
        { to: '/convert', label: 'Convert', icon: <RecordVoiceOver /> },
        { to: '/profile', label: 'Profile', icon: <AccountCircle /> },
        { to: '/billing', label: 'Billing', icon: <CreditCard /> },
        { to: '/faq', label: 'FAQ', icon: <HelpOutline /> },
        { to: '/support', label: 'Support', icon: <EmojiPeople /> },
        {
          to: '/logout',
          label: 'Logout',
          icon: <PowerSettingsNew />,
          handler: dispatchLogout
        }
      ]
    : [
        { to: '/', label: 'Home', icon: <Home /> },
        { to: '/sign-in', label: 'Sign In', icon: <MobileFriendly /> },
        { to: '/sign-up', label: 'Sign Up', icon: <ExitToApp /> }
      ];
  const [isAnchorLinks, setIsAnchorLinks] = useState(
    location.pathname === '/' && !isAuth
  );
  const {
    open,
    toggleDrawer,
    handleDrawerOpen,
    handleDrawerClose
  } = useDrawer();

  useEffect(() => {
    setIsAnchorLinks(location.pathname === '/' && !isAuth);
  }, [location, isAuth]);

  return (
    <>
      <TopNavbar
        open={open}
        isAnchorLinks={isAnchorLinks}
        toggleDrawer={toggleDrawer}
        logout={dispatchLogout}
        isAuth={isAuth}
        location={location}
        title="NaturalTTS"
      />
      <SideDrawer
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      >
        {content.map(item => {
          const handler = item.handler
            ? () => {
                handleDrawerClose();
                item.handler();
              }
            : handleDrawerClose;
          return (
            <Box key={item.to}>
              <ListLink
                to={item.to}
                label={item.label}
                mobile={true}
                icon={item.icon}
                handler={handler}
                action={!!item.handler}
                active={item.to === location.pathname}
              />
              <Divider />
            </Box>
          );
        })}
      </SideDrawer>
    </>
  );
};

Navigation.propTypes = {
  location: PropTypes.object
};

export default withRouter(Navigation);
