import { takeLatest, call, put } from 'redux-saga/effects';

import {
  UPGRADE_SUBSCRIPTION_REQUEST,
  upgradeSubscriptionResult,
  upgradeSubscriptionFail
} from '../../actions/paymentActions';
import { converterGetVoicesRequest } from '../../actions/converterActions';
import { callUpgradeSubscription, asyncFlow } from '../../api';
import Storage from '../../../storage';
import { sagaErrorBoundaries } from '../../utils';

function* authEffect({ subscription, user: userPayload }) {
  const user = yield call(Storage.getItem, 'NATURALTTS_USER', true);
  console.log('authEffectSubs', { ...user, ...subscription });
  yield call(
    Storage.setItem,
    'NATURALTTS_USER',
    { ...user, ...subscription, ...userPayload },
    true
  );
  yield put(converterGetVoicesRequest());
}

const upgradeSubscriptionFlow = asyncFlow(
  callUpgradeSubscription,
  upgradeSubscriptionResult,
  upgradeSubscriptionFail,
  authEffect
);

export default function* upgradeSubscriptionSaga() {
  yield takeLatest(
    UPGRADE_SUBSCRIPTION_REQUEST,
    sagaErrorBoundaries(upgradeSubscriptionFlow)
  );
}
