import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BroadAudioPlayer as AudioPlayer } from 'naturaltts-ui-audio-player';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0 0 20px 20px'
  },
  icon: {
    width: 40,
    height: 40,
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 50
    }
  },
  downloadIconContainer: {
    margin: '0 10px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 35px'
    },
    flex: '0 0'
  }
}));

const BroadAudioPlayer = ({ src, title, description, height = '140px' }) => {
  return (
    <AudioPlayer
      title={title}
      description={description}
      useStyles={useStyles}
      height={height}
      src={src}
    />
  );
};

BroadAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  height: PropTypes.string
};

export default BroadAudioPlayer;
