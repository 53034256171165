import React from 'react';
import { Grid, Tabs, Tab, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

import ConverterPlayer from './ConverterPlayer';
import { displayErrorMessage, isFieldError } from '../../utils';
import ConverterLimitField from '../../ui/Converter/ConverterLimitField';
import SelectVoiceField from '../../ui/Converter/SelectVoiceField';
import SelectVoiceLanguageField from '../../ui/Converter/SelectVoiceLanguageField';
import Snackbar from '../../ui/Snackbar/Snackbar';
import Dropzone from '../../ui/Dropzone/Dropzone';
import ComponentGuard from '../auth/Guards/ComponentGuard';
import ConverterSubmitButton from '../../ui/Converter/ConverterSubmitButton';
import inlineGuard from '../auth/Guards/inlineGuard';

const audioTaskIsLoadingFunc = audioTask => {
  return (
    audioTask.TaskStatus === 'scheduled' ||
    audioTask.TaskStatus === 'inProgress'
  );
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      zIndex: 3,
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(5),
      padding: theme.spacing(5),
      margin: theme.spacing(0, 5)
    },
    audioPlayerContainer: {
      zIndex: 2,
      marginTop: theme.spacing(-4)
    },
    textFieldHelper: {
      fontSize: 14
    },
    buttonsContainer: {
      position: 'relative'
    },
    openDropzoneButtonContainer: {
      position: 'absolute',
      left: 10
    }
  };
});

const ConverterView = React.forwardRef(
  (
    {
      attempt,
      text,
      voice,
      textType,
      onFieldChange,
      handleSubmit,
      audioTask,
      handleResetAudio,
      onChangeLang,
      touched,
      converterError,
      free,
      voiceLanguages,
      voiceLanguage,
      voices,
      errors,
      loading,
      clearConverterTextError,
      containerGrid,
      displayPlayer,
      openDropzone,
      closeDropzone,
      dropzoneOpened,
      setParseDocumentResult,
      parseDocumentRequest,
      parseDocumentError,
      clearParseDocumentError,
      setParseDocumentError,
      parseDocumentLoading,
      plan
    },
    ref
  ) => {
    const classes = useStyles();
    const audioTaskIsLoading = audioTaskIsLoadingFunc(audioTask);
    console.log('voiceLanguage1', voiceLanguage);
    console.log('converterError', converterError);

    const handleChangeTypeText = (event, newValue) => {
      onFieldChange()('textType', newValue ? 'ssml' : 'text');
    };

    const selectVoiceFieldProps = {
      touched,
      errors,
      voices,
      value: voice,
      free,
      isFieldError,
      displayErrorMessage,
      onFieldChange
    };
    const SelectVoiceLanguageFieldProps = {
      touched,
      voiceLanguages,
      value: voiceLanguage,
      onChangeLang
    };
    const audioIsReady = !!(audioTask.TaskId && !audioTaskIsLoading);
    const formHelperTextProps = {
      variant: 'standard',
      classes: { root: classes.textFieldHelper }
    };

    const computedContainerGrid = containerGrid || {
      xs: 12,
      sm: 10,
      lg: 8,
      xl: 7
    };
    const protectedConverterTopFieldsGridSm = free ? 4 : 12;
    const protectedConverterTopFieldsGridMd = free ? 4 : 6;
    const converterPlayerProps = {
      audioTask,
      displayPlayer,
      containerGrid: computedContainerGrid,
      audioIsReady
    };

    const isLoading = loading || parseDocumentLoading || audioTaskIsLoading;
    const ssmlAllowed = inlineGuard(plan, 'ssml');

    return (
      <>
        <Grid
          item
          {...computedContainerGrid}
          ref={ref}
          container
          spacing={4}
          className={classes.root}
        >
          <Grid item xs={12} container justify="space-between" spacing={2}>
            {free && (
              <Grid item xs={12} sm={4}>
                <ConverterLimitField limit={200} textLength={text.length} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={protectedConverterTopFieldsGridSm}
              md={protectedConverterTopFieldsGridMd}
            >
              <SelectVoiceLanguageField {...SelectVoiceLanguageFieldProps} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={protectedConverterTopFieldsGridSm}
              md={protectedConverterTopFieldsGridMd}
            >
              <SelectVoiceField {...selectVoiceFieldProps} />
            </Grid>
          </Grid>
          {!free && (
            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Tabs
                value={textType === 'text' ? 0 : 1}
                onChange={handleChangeTypeText}
              >
                <Tab label="Text" />
                {ssmlAllowed ? <Tab label="SSML" /> : null}
              </Tabs>
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              placeholder={
                textType === 'text' ? 'Text for Speech' : 'SSML for Speech'
              }
              FormHelperTextProps={formHelperTextProps}
              multiline={true}
              fullWidth={true}
              rows="6"
              variant="outlined"
              name="text"
              component={TextField}
            />
          </Grid>
          <Grid
            item
            spacing={2}
            container
            justify="center"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <ComponentGuard permissions="upload">
              <Grid item className={classes.openDropzoneButtonContainer}>
                {!parseDocumentLoading && (
                  <Fab
                    aria-label="attach-file"
                    color="primary"
                    onClick={openDropzone}
                  >
                    <AttachFile />
                  </Fab>
                )}
              </Grid>
            </ComponentGuard>
            <ConverterSubmitButton
              audioIsReady={audioIsReady}
              isLoading={isLoading}
              handleResetAudio={handleResetAudio}
              handleSubmit={handleSubmit}
              disableConvert={free && attempt}
            />
          </Grid>
        </Grid>
        <ConverterPlayer {...converterPlayerProps} />
        {dropzoneOpened ? (
          <Dropzone
            closeDropzone={closeDropzone}
            setParseDocumentResult={setParseDocumentResult}
            parseDocument={parseDocumentRequest}
            setParseDocumentError={setParseDocumentError}
          />
        ) : null}
        <Snackbar
          variant="error"
          message={converterError}
          onClose={clearConverterTextError}
        />
        <Snackbar
          variant="error"
          message={parseDocumentError}
          onClose={clearParseDocumentError}
        />
      </>
    );
  }
);

ConverterView.propTypes = {
  text: PropTypes.any,
  voice: PropTypes.any,
  onFieldChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.any,
  converterError: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.any,
  free: PropTypes.bool.isRequired,
  audioTask: PropTypes.any,
  handleResetAudio: PropTypes.func,
  clearConverterTextError: PropTypes.func,
  voices: PropTypes.array,
  voiceLanguages: PropTypes.array,
  voiceLanguage: PropTypes.string,
  textType: PropTypes.string,
  onChangeLang: PropTypes.func,
  containerGrid: PropTypes.object,
  displayPlayer: PropTypes.bool,
  openDropzone: PropTypes.func.isRequired,
  dropzoneOpened: PropTypes.bool.isRequired,
  closeDropzone: PropTypes.func.isRequired,
  setParseDocumentResult: PropTypes.func.isRequired,
  parseDocumentRequest: PropTypes.func.isRequired,
  parseDocumentError: PropTypes.any,
  clearParseDocumentError: PropTypes.func.isRequired,
  setParseDocumentError: PropTypes.func.isRequired,
  parseDocumentLoading: PropTypes.bool.isRequired,
  plan: PropTypes.any,
  attempt: PropTypes.number.isRequired
};

export default ConverterView;
