import {
  startConverterTaskPolling,
  START_CONVERTER_TASK_POLLING,
  STOP_CONVERTER_TASK_POLLING,
  FAIL_CONVERTER_TASK_POLLING,
  failConverterTaskPolling,
  converterTaskPollingResult,
  stopConverterTaskPolling
} from '../../actions/converterActions';
import { callGetConverterTask } from '../../api';
import PollingSaga from '../generalSaga/PollingSaga';

const actions = {
  STOP_POLLING: STOP_CONVERTER_TASK_POLLING,
  START_POLLING: START_CONVERTER_TASK_POLLING,
  FAIL_POLLING: FAIL_CONVERTER_TASK_POLLING
};
const actionCreatorts = {
  startPolling: startConverterTaskPolling,
  failPolling: failConverterTaskPolling,
  stopPolling: stopConverterTaskPolling,
  pollingResult: converterTaskPollingResult
};
const options = { delay: 3000, makeRequest: callGetConverterTask };

const converterTaskPollingSaga = new PollingSaga(
  actions,
  actionCreatorts,
  options,
  {
    entity: 'task'
  }
);
export default converterTaskPollingSaga;

// import { fork, take, race, put, takeEvery } from 'redux-saga/effects';
// function* converterGetTaskPollingEffect({ task }) {
//   console.log('converterGetTaskPollingEffect', task);
//   if (
//     (task && task.TaskStatus === 'completed') ||
//     task.TaskStatus === 'failed'
//   ) {
//     console.log('Finihs1112121212');
//     return yield put(stopConverterTaskPolling());
//   }
//   yield startPolling(task.TaskId);
// }

// function delay(duration) {
//   const promise = new Promise(resolve => {
//     setTimeout(() => resolve(true), duration);
//   });
//   return promise;
// }

// const converterGetTaskPolling = asyncFlow(
//   callGetConverterTask,
//   converterTaskPollingResult,
//   failConverterTaskPolling,
//   converterGetTaskPollingEffect,
//   delay,
//   3000
// );

// function* watchTaskPolling() {
//   while (true) {
//     const { taskId } = yield take(START_CONVERTER_TASK_POLLING);
//     console.log('yyyyyyy', taskId);
//     yield race([
//       take(STOP_CONVERTER_TASK_POLLING),
//       fork(converterGetTaskPolling, { taskId })
//     ]);
//   }
// }

// function* failTaskPollingFlow() {
//   yield put(stopConverterTaskPolling());
// }

// export function* startPolling(taskId) {
//   yield put(startConverterTaskPolling(taskId));
// }

// export default function* converterTaskPollingSaga() {
//   yield fork(sagaErrorBoundaries(watchTaskPolling));
//   yield takeEvery(
//     FAIL_CONVERTER_TASK_POLLING,
//     sagaErrorBoundaries(failTaskPollingFlow)
//   );
// }
