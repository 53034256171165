import { createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#00CEB2',
      base: 'linear-gradient(to right, #0086C8, #00CEB2)',
      light: 'linear-gradient(to right, #0086C8, #00CEB2)',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#0086C8',
      dark: '#005580'
    },
    text: {
      primary: '#14161A',
      secondary: '#FFFFFF'
      //disabled: '#6f7a90'
    }
  },
  typography: {
    //fontWeightRegular: 300,
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  spacing: 4
  // overrides: {
  //   MuiOutlinedInput: {
  //     root: {
  //       '&$focused $notchedOutline': {
  //         borderColor: '#00c3dc'
  //       }
  //     }
  //   },
  //   MuiFormLabel: {
  //     root: {
  //       '&$focused': {
  //         color: '#00c3dc'
  //       }
  //     }
  //   }
  // }
});

export default muiTheme;
