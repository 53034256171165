import { OutlinedInput } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useSelectContainerComponentStyles = makeStyles(theme => {
  return {
    inputLabel: {
      color: theme.palette.grey['500'],
      backgroundColor: theme.palette.common.white,
      padding: '0 5px'
    },
    input: {
      borderRadius: 25
    },
    select: {
      '&:focus': {
        backgroundColor: theme.palette.common.white
      }
    },
    chip: {
      marginLeft: theme.spacing(1)
    }
  };
});

export const useSelectComponentStyles = makeStyles(theme => ({
  select: {
    '&:focus': {
      backgroundColor: theme.palette.common.white
    }
  },
  selectMenu: {
    display: 'flex'
  }
}));

export const StyledOutlinedInput = withStyles({
  notchedOutline: {
    borderRadius: 25
  }
})(OutlinedInput);
