import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment-timezone';
import chunk from 'lodash/chunk';

import { converterHistoryRequest } from '../../redux/actions/converterActions';
import BasicAudioPlayer from '../../ui/AudioPlayer/BasicAudioPlayer';
import CircularLoader from '../../ui/Loader/CircularLoader';
import { converterHistoryConstrain } from '../../config/constrictions';

const tz = moment.tz.guess(false);
const timeZone = moment.tz.zone(tz) ? tz : 'America/New_York';

const useStyles = makeStyles(theme => {
  return {
    root: {
      // height: '100%',
      '& .infinite-scroll-component__outerdiv': {
        width: '100%'
      }
    },
    audioPlayersContainer: {
      padding: theme.spacing(3, 2)
    },
    title: {
      width: '100%'
    }
  };
});

const usePlayerStyles = makeStyles(theme => {
  return {
    root: {
      margin: theme.spacing(8, 2)
    }
  };
});

const ConverterHistory = ({
  email,
  converterHistoryRequest,
  containerGrid,
  converterHistory,
  hasMore,
  limit
}) => {
  const classes = useStyles();
  const isConverterHistoryPresent =
    Array.isArray(converterHistory) && !!converterHistory.length;
  const fetchConverterHistory = () => {
    const lastAudioTask = isConverterHistoryPresent
      ? converterHistory[converterHistory.length - 1]
      : null;
    console.log('lastAudioTask', lastAudioTask);
    converterHistoryRequest(email, 10, lastAudioTask.createdAt);
  };
  const infiniteScrollProps = {
    dataLength: converterHistory.length,
    next: fetchConverterHistory,
    style: { overflow: 'inherit' },
    loader: <CircularLoader />,
    ...converterHistoryConstrain(hasMore, limit)
  };
  useEffect(() => {
    console.log('email1111', email);
    converterHistoryRequest(email, 10);
  }, [converterHistoryRequest, email]);
  const computedConverterHistory =
    typeof limit === 'number'
      ? chunk(converterHistory, limit)[0]
      : converterHistory;

  return isConverterHistoryPresent ? (
    <Grid container item {...containerGrid} className={classes.root}>
      <Typography
        align="center"
        component="h2"
        variant="h5"
        color="textPrimary"
        className={classes.title}
      >
        History
      </Typography>
      <InfiniteScroll {...infiniteScrollProps}>
        <Grid container item className={classes.audioPlayersContainer}>
          {computedConverterHistory.map(audioTask => {
            const title = `${audioTask.voiceId} ${audioTask.gender}`;
            return (
              <BasicAudioPlayer
                key={audioTask.id}
                src={audioTask.audioPath}
                title={title}
                description={audioTask.lang}
                textControl={true}
                download={true}
                volumeControl={true}
                bottomControlPanel={true}
                convertedText={audioTask.text}
                variation="primary"
                useStyles={usePlayerStyles}
                date={moment
                  .tz(audioTask.createdAt, timeZone)
                  .format('MM-DD-YYYY HH:mm A')}
                width="100%"
              />
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Grid>
  ) : null;
};

ConverterHistory.propTypes = {
  email: PropTypes.string.isRequired,
  containerGrid: PropTypes.object.isRequired,
  converterHistory: PropTypes.any,
  limit: PropTypes.any,
  converterHistoryRequest: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired
};
export default connect(
  state => {
    console.log('stateHistort', state);
    return {
      converterHistory: state.converter.converterHistory,
      hasMore: state.converter.hasMore,
      email: state.auth.user.email
    };
  },
  { converterHistoryRequest }
)(ConverterHistory);
