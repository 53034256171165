export const SUPPORT_QUESTION_REQUEST = 'SUPPORT_QUESTION_REQUEST';
export const SUPPORT_QUESTION_RESULT = 'SUPPORT_QUESTION_RESULT';
export const SUPPORT_QUESTION_FAIL = 'SUPPORT_QUESTION_FAIL';
export const CLEAR_SUPPORT_QUESTION_ERROR = 'CLEAR_SUPPORT_QUESTION_ERROR';
export const CLEAR_SUPPORT_QUESTION_SUCCESS = 'CLEAR_SUPPORT_QUESTION_SUCCESS';

export function supportQuestionRequest(question) {
  console.log('supportQuestionRequstAction');
  return {
    type: SUPPORT_QUESTION_REQUEST,
    loading: true,
    question
  };
}

export function supportQuestionResult({ message }) {
  console.log('supportQuestion');
  return {
    type: SUPPORT_QUESTION_RESULT,
    loading: false,
    success: message
  };
}

export function supportQuestionFail(error) {
  return {
    type: SUPPORT_QUESTION_FAIL,
    loading: false,
    error
  };
}

export function clearSupportQuestionRequestError() {
  return {
    type: CLEAR_SUPPORT_QUESTION_ERROR
  };
}

export function clearSupportQuestionSuccess() {
  return {
    type: CLEAR_SUPPORT_QUESTION_SUCCESS
  };
}
