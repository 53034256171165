import { fork, take, race, put, takeEvery } from 'redux-saga/effects';

import { asyncFlow } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

export default class PollingSaga {
  static END_STATUSES = ['completed', 'failed', 'succeeded'];
  static ENTETIES = {
    job: {
      name: 'job',
      id: 'Id',
      status: 'JobStatus'
    },
    task: {
      name: 'task',
      id: 'TaskId',
      status: 'TaskStatus'
    }
  };
  constructor(
    { STOP_POLLING, START_POLLING, FAIL_POLLING },
    { startPolling, failPolling, stopPolling, pollingResult },
    { delay, makeRequest },
    { entity }
  ) {
    this.STOP_POLLING = STOP_POLLING;
    this.START_POLLING = START_POLLING;
    this.FAIL_POLLING = FAIL_POLLING;
    this.startPollingActionCreator = startPolling;
    this.failPolling = failPolling;
    this.stopPolling = stopPolling;
    this.pollingResult = pollingResult;
    this.options = { delay, makeRequest };
    this.entity = PollingSaga.ENTETIES[entity];
    this.lastPollingEffect = this.lastPollingEffect.bind(this);
    this.failTaskPollingFlow = this.failTaskPollingFlow.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.watchPolling = this.watchPolling.bind(this);
    this.getSaga = this.getSaga.bind(this);
    this.build();
  }

  makeDelay(duration = 100) {
    const promise = new Promise(resolve => {
      setTimeout(() => resolve(true), duration);
    });
    return promise;
  }

  build() {
    this.poll = asyncFlow(
      this.options.makeRequest,
      this.pollingResult,
      this.failPolling,
      this.lastPollingEffect,
      this.makeDelay,
      this.options.delay
    );
  }

  *lastPollingEffect(data) {
    console.log('LastPollingEffect', data, this.entity);
    const entity = data[this.entity.name];
    if (
      entity &&
      PollingSaga.END_STATUSES.includes(
        entity[this.entity.status].toLowerCase()
      )
    ) {
      console.log('EndPolling');
      return yield put(this.stopPolling());
    }
    yield this.startPolling(entity[this.entity.id]);
  }

  *failTaskPollingFlow() {
    yield put(this.stopPolling());
  }

  *startPolling(value) {
    yield put(this.startPollingActionCreator(value));
  }

  *watchPolling() {
    while (true) {
      const { Id } = yield take(this.START_POLLING);
      console.log('yyyyyyy', Id);
      yield race([take(this.STOP_POLLING), fork(this.poll, { Id })]);
    }
  }

  *getSaga() {
    yield fork(sagaErrorBoundaries(this.watchPolling));
    yield takeEvery(
      this.FAIL_POLLING,
      sagaErrorBoundaries(this.failTaskPollingFlow)
    );
  }
}
