import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as ReactAnchorLink } from 'react-anchor-link-smooth-scroll';
import cx from 'classnames';

const useStyles = makeStyles(theme => {
  return {
    root: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.htmlFontSize,
      textDecoration: 'none'
    }
  };
});

function AnchorLink({
  to,
  children,
  className: customClassname,
  offset = 100
}) {
  const anchorLinkStyles = useStyles();
  const compProps = {
    href: to,
    offset,
    className: cx(customClassname, anchorLinkStyles.root)
  };
  return <ReactAnchorLink {...compProps}>{children}</ReactAnchorLink>;
}

AnchorLink.propTypes = {
  to: PropTypes.string.isRequired,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any
};

export default AnchorLink;
