import { takeEvery } from 'redux-saga/effects';

import {
  CANCEL_SUBSCRIPTION_REQUEST,
  cancelSubscriptionResult,
  cancelSubscriptionFail
} from '../../actions/paymentActions';
import { asyncFlow, callCancelSubscription } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

function* cancelSubscription({ email }) {
  const cancelSubscriptionFlow = asyncFlow(
    callCancelSubscription,
    cancelSubscriptionResult,
    cancelSubscriptionFail
  );
  yield cancelSubscriptionFlow({
    email
  });
}

export default function* cancelSubscriptionSaga() {
  yield takeEvery(
    CANCEL_SUBSCRIPTION_REQUEST,
    sagaErrorBoundaries(cancelSubscription)
  );
}
