/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import Loader from '../../ui/Loader/CircularLoader';
import BaseButton from '../../ui/Button/BaseButton';
import Snackbar from '../../ui/Snackbar/Snackbar';

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            height: theme.spacing(18),
            width: '48%',
            '&:first-of-type' : {
                marginRight: '4%'
            }
        },
        inputLabel: {
            color: theme.palette.grey['500']
        },
        buttonContainer: {
            marginTop: theme.spacing(6)
        },
        textFieldHelper: {
            fontSize: 14
        }
    }
});

const SupportForm = ({
    loading,
    handleSubmit,
    clearSupportQuestionSuccess,
    clearSupportQuestionRequestError,
    successMessage,
    errorMessage,
}) => {
    const classes = useStyles();
    const inputLableProps = {
        shrink: true, classes: { root: classes.inputLabel }
    }
    const formHelperTextProps = {
        variant: 'standard',
        classes: { root: classes.textFieldHelper }
      };
    return (
        <Grid component="form" noValidate autoComplete="off" className={classes.root}>
            <Field
                required
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                disabled={true}
                InputLabelProps={inputLableProps}
                placeholder="Enter your name"
                className={classes.textField}
                margin="normal"
                component={TextField}
            />
            <Field
                required
                id="email"
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                placeholder="Enter your email"
                disabled={true}
                InputLabelProps={inputLableProps}
                className={classes.textField}
                margin="normal"
                component={TextField}
            />
            <Field
                placeholder='Enter question'
                FormHelperTextProps={formHelperTextProps}
                multiline={true}
                fullWidth={true}
                rows="6"
                variant="outlined"
                name="question"
                component={TextField}
            />
            {loading ? (
                <Loader />
            ) : (
                    <Grid container direction="column" alignItems="center" className={classes.buttonContainer}>
                        <BaseButton onClick={handleSubmit} >
                            Send
                        </BaseButton>
                    </Grid>
                )}
            <Snackbar
                variant="success"
                message={successMessage}
                onClose={clearSupportQuestionSuccess}
            />
            <Snackbar
                variant="error"
                message={errorMessage}
                onClose={clearSupportQuestionRequestError}
            />
        </Grid>
    );
};

SupportForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateUserError: PropTypes.any,
    successMessage: PropTypes.any,
    clearSupportQuestionRequestError: PropTypes.func.isRequired,
    clearSupportQuestionSuccess: PropTypes.func.isRequired,
};


export default SupportForm;
