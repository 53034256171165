import { spawn } from 'redux-saga/effects';

import authSaga from './authSaga';
import userSaga from './userSaga';
import converterSaga from './converterSaga';
import paymentSaga from './paymentSaga';
import supportSaga from './supportSaga';
import documentSaga from './documentSaga';
//import websocketSaga from './websocketSaga';

export default function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(userSaga);
  yield spawn(converterSaga);
  yield spawn(paymentSaga);
  yield spawn(supportSaga);
  yield spawn(documentSaga);
  //yield spawn(websocketSaga);
}
