import React, { useEffect, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';

import { onFieldChangeFunc } from '../../utils';
import {
  convertTextRequest,
  converterResetAudioRequest,
  converterGetVoicesRequest,
  changeVoiceLanguage,
  clearConverterTextError,
  converterHistoryRequest
} from '../../redux/actions/converterActions';
import {
  parseDocumentRequest,
  clearParseDocumentError,
  setParseDocumentError
} from '../../redux/actions/documentActions';
import ConverterView from './ConverterView';
import voiceLanguageSelector from '../../selectors/voiceLanguageSelector';
import voicesSelector from '../../selectors/voicesSelector';

const ConverterContainer = ({
  values: { text, voice, textType },
  resetForm,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  converterResetAudioRequest,
  converterGetVoicesRequest,
  clearConverterTextError,
  changeVoiceLanguage,
  touched,
  free = false,
  displayPlayer = true,
  voices,
  voiceLanguages,
  voiceLanguage,
  audioTask,
  converterError,
  errors,
  loading,
  freeVoicesFlag,
  containerGrid,
  parseDocumentRequest,
  clearParseDocumentError,
  parseDocumentError,
  setParseDocumentError,
  parseDocumentLoading,
  user,
  converterHistoryRequest,
  attempt
}) => {
  console.log('voiceLanguages', voiceLanguages);
  console.log('voice', voice);
  const plan = user ? user.plan : '';
  const email = user ? user.email : '';
  const onFieldChange = useCallback(
    () => onFieldChangeFunc(setFieldValue, setFieldTouched),
    [setFieldValue, setFieldTouched]
  );
  const onChangeLang = useCallback(
    lang => {
      changeVoiceLanguage(lang);
      setFieldValue('lang', lang);
    },
    [changeVoiceLanguage, setFieldValue]
  );
  const handleResetAudio = useCallback(() => {
    converterResetAudioRequest();
    setFieldTouched('text', false);
    resetForm({
      text: '',
      voice,
      textType,
      lang: voiceLanguage
    });
    converterHistoryRequest(email, 10);
  }, [
    converterResetAudioRequest,
    setFieldTouched,
    resetForm,
    voice,
    textType,
    voiceLanguage,
    converterHistoryRequest,
    email
  ]);
  console.log('freeVoicesFlag', freeVoicesFlag, free);
  const [dropzoneOpened, setDropzoneOpen] = useState(false);
  const openDropzone = () => {
    setDropzoneOpen(true);
  };
  const closeDropzone = () => {
    setDropzoneOpen(false);
  };
  const setParseDocumentResult = text => {
    setFieldValue('text', text);
  };
  const converterProps = {
    attempt,
    text,
    voice,
    textType,
    audioTask,
    onFieldChange,
    handleSubmit,
    handleResetAudio,
    onChangeLang,
    touched,
    free,
    voices,
    errors,
    converterError,
    clearConverterTextError,
    loading,
    voiceLanguages,
    voiceLanguage,
    containerGrid,
    displayPlayer,
    dropzoneOpened,
    openDropzone,
    setParseDocumentResult,
    closeDropzone,
    parseDocumentRequest,
    parseDocumentError,
    clearParseDocumentError,
    setParseDocumentError,
    parseDocumentLoading,
    plan
  };
  const onClickAway = e => {
    if (e.target.closest('#dropzone')) {
      return;
    }
    setDropzoneOpen(false);
  };

  useEffect(() => {
    if (!voices.length || free !== freeVoicesFlag) {
      converterGetVoicesRequest(free);
    }
    if (voices[0]) {
      onFieldChange()(
        'voice',
        voices[0]
          ? voices[0].LanguageCode === 'en-US'
            ? voices[1].Id
            : voices[0].Id
          : ''
      );
    }
  }, [converterGetVoicesRequest, onFieldChange, voices, free, freeVoicesFlag]);
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <ConverterView {...converterProps} />
    </ClickAwayListener>
  );
};

ConverterContainer.propTypes = {
  values: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.any,
  displayConverterHistory: PropTypes.bool,
  audioTask: PropTypes.object,
  converterError: PropTypes.any,
  free: PropTypes.bool,
  voices: PropTypes.array,
  converterResetAudioRequest: PropTypes.func.isRequired,
  converterGetVoicesRequest: PropTypes.func.isRequired,
  changeVoiceLanguage: PropTypes.func.isRequired,
  voiceLanguages: PropTypes.array,
  voiceLanguage: PropTypes.string,
  freeVoicesFlag: PropTypes.bool,
  clearConverterTextError: PropTypes.func.isRequired,
  containerGrid: PropTypes.object,
  displayPlayer: PropTypes.bool,
  parseDocumentRequest: PropTypes.func.isRequired,
  clearParseDocumentError: PropTypes.func.isRequired,
  parseDocumentError: PropTypes.any,
  setParseDocumentError: PropTypes.func.isRequired,
  parseDocumentLoading: PropTypes.bool.isRequired,
  user: PropTypes.any,
  converterHistoryRequest: PropTypes.func.isRequired,
  attempt: PropTypes.number.isRequired
};

const validateConverter = (values, props) => {
  let errors = {};
  if (props.free && values.text.length > 200) {
    errors.text = 'Free version has limit 200 symbols';
  }
  return errors;
};

const ConverterWithFormik = withFormik({
  mapPropsToValues: props => {
    console.log('mapsToPropsConverter', props);
    return {
      // TODO: make different audioTask for free and converter for registered users
      text: props.audioTask.Text && props.free ? props.audioTask.Text : '',
      voice: 'Joanna',
      textType: 'text',
      lang: props.voiceLanguage
    };
  },
  handleSubmit: (
    values,
    { props: { user, convertTextRequest, free, voices } }
  ) => {
    console.log('handleConvertSubmit', values);
    const usr = user ? user : {};
    const { LanguageName, Gender, SupportedEngines, ...rest } =
      voices.find(
        voice => values.lang === voice.LanguageCode && values.voice === voice.Id
      ) || {};
    console.log('data', Gender, rest, voices);
    const engine =
      SupportedEngines && SupportedEngines.includes('neural')
        ? 'neural'
        : 'standard';
    convertTextRequest(
      usr.username,
      usr.email,
      values.voice,
      values.text,
      free,
      values.textType,
      LanguageName,
      Gender,
      engine
    );
  },
  validate: validateConverter,
  validationSchema: Yup.object().shape({
    text: Yup.string()
      .trim()
      .min(5, 'Text must be at least 5')
      .required('This field is required'),
    voice: Yup.string().required('Required picked voice')
  })
})(ConverterContainer);

export default connect(
  state => {
    return {
      loading: state.converter.loading,
      audioTask: state.converter.audioTask,
      converterError: state.converter.converterError,
      user: state.auth.user,
      voices: voicesSelector(state),
      voiceLanguages: voiceLanguageSelector(state),
      voiceLanguage: state.converter.voiceLanguage,
      freeVoicesFlag: state.converter.freeVoicesFlag,
      attempt: state.converter.attempt,
      parseDocumentError: state.document.error,
      parseDocumentLoading: state.document.loading
    };
  },
  {
    convertTextRequest,
    converterResetAudioRequest,
    converterGetVoicesRequest,
    changeVoiceLanguage,
    clearConverterTextError,
    parseDocumentRequest,
    clearParseDocumentError,
    setParseDocumentError,
    converterHistoryRequest
  }
)(ConverterWithFormik);
