import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
// import { Grid } from '@material-ui/core';
import { StripeProvider } from 'react-stripe-elements';

import muiTheme from './theme';
import Home from './pages/home/Home';
import Navigation from './pages/base/Navigation';
import ConverterPage from './pages/converter/ConverterPage';
import FAQ from './pages/FAQ/FAQ';
// import useStripe from './hooks/useStripe';
import configureStore from './redux/store';
import Profile from './pages/auth/Profile/Profile';
import ProtectedRoute from './pages/auth/ProtectedRoute';
import AuthRoute from './pages/auth/AuthRoute';
// import ConfirmationPage from './pages/auth/Confirmation/ConfirmationPage';
// import ConfirmedEmailPage from './pages/auth/Confirmation/ConfirmedEmailPage';
// import ResetPassword from './pages/auth/ForgotPassword/ResetPassword';
// import SignIn from './pages/auth/SignIn/SignIn';
// import SignUp from './pages/auth/SignUp/SignUp';
// import ForgotPassword from './pages/auth/ForgotPassword/ForgotPassword';
import history from './utils/history';
import Support from './pages/support/Support';
//import BillingPage from './pages/billing/BillingPage';
import ContactUs from './pages/policies/ContactUs';

const store = configureStore();

const LazyBillingPage = React.lazy(() => import('./pages/billing/BillingPage'));

// const LazyConverterPage = React.lazy(() =>
//   import('./pages/converter/ConverterPage')
// );

const LazyForgotPassword = React.lazy(() =>
  import('./pages/auth/ForgotPassword/ForgotPassword')
);
const LazyResetPassword = React.lazy(() =>
  import('./pages/auth/ForgotPassword/ResetPassword')
);
const LazyConfirmationPage = React.lazy(() =>
  import('./pages/auth/Confirmation/ConfirmationPage')
);
const LazyConfirmedEmailPage = React.lazy(() =>
  import('./pages/auth/Confirmation/ConfirmedEmailPage')
);
const LazySignIn = React.lazy(() => import('./pages/auth/SignIn/SignIn'));
const LazySignUp = React.lazy(() => import('./pages/auth/SignUp/SignUp'));
const LazyRefund = React.lazy(() => import('./pages/policies/Refund'));
const LazyTerms = React.lazy(() => import('./pages/policies/Terms'));
const LazyPrivacyPolicy = React.lazy(() =>
  import('./pages/policies/PrivacyPolicy')
);
const LazyUpdatePaymentPage = React.lazy(() =>
  import('./pages/payment/UpdatePaymentPage')
);
const SignUpStackSocial = React.lazy(() =>
  import('./pages/auth/SignUp/SignUpStackSocial')
);

const App = () => {
  //const stripe = useStripe(process.env.REACT_APP_STRIPE_API_KEY);
  return (
    <StripeProvider apiKey={'123123'}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Provider store={store}>
            <Router history={history}>
              <>
                <Navigation />
                <Suspense fallback={<div />}>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <AuthRoute exact path="/sign-in" component={LazySignIn} />
                    <AuthRoute exact path="/sign-up" component={LazySignUp} />
                    <AuthRoute
                      exact
                      path="/stack-social"
                      component={SignUpStackSocial}
                    />
                    <AuthRoute
                      exact
                      path="/forgot-password"
                      component={LazyForgotPassword}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      component={LazyResetPassword}
                    />
                    <Route exact path="/refund" component={LazyRefund} />
                    <Route exact path="/terms" component={LazyTerms} />
                    <Route
                      exact
                      path="/privacy-policy"
                      component={LazyPrivacyPolicy}
                    />
                    <ProtectedRoute
                      exact
                      path="/confirmation"
                      component={LazyConfirmationPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/update-payment"
                      component={LazyUpdatePaymentPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/confirmed"
                      component={LazyConfirmedEmailPage}
                    />
                    <ProtectedRoute exact path="/profile" component={Profile} />
                    <ProtectedRoute
                      exact
                      scope={['free', 'personal', 'commercial']}
                      path="/billing"
                      component={LazyBillingPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/convert"
                      component={ConverterPage}
                    />
                    <Route exact path="/FAQ" component={FAQ} />
                    <Route exact path="/contact-us" component={ContactUs} />
                    <ProtectedRoute exact path="/support" component={Support} />
                  </Switch>
                </Suspense>
              </>
            </Router>
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </StripeProvider>
  );
};

export default App;
