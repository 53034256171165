import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import CircularLoader from '../Loader/CircularLoader';
import BaseButton from '../Button/BaseButton';

const ConverterSubmitButton = ({
  audioIsReady,
  isLoading,
  handleResetAudio,
  handleSubmit,
  disableConvert
}) => {
  console.log('disableConvert', disableConvert);
  return (
    <Grid item>
      {isLoading ? (
        <CircularLoader />
      ) : audioIsReady ? (
        <BaseButton
          variation="light"
          uppercase={true}
          onClick={handleResetAudio}
          disabled={isLoading}
        >
          Reset
        </BaseButton>
      ) : (
        <BaseButton
          variation="light"
          uppercase={true}
          onClick={handleSubmit}
          disabled={isLoading || disableConvert}
        >
          Convert
        </BaseButton>
      )}
    </Grid>
  );
};

ConverterSubmitButton.propTypes = {
  audioIsReady: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleResetAudio: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disableConvert: PropTypes.bool.isRequired
};

export default ConverterSubmitButton;
