import { takeEvery } from 'redux-saga/effects';

import {
  EXTEND_PACKAGE_LIMIT_REQUEST,
  extendPackageLimitResult,
  extendPackageLimitFail
} from '../../actions/paymentActions';
import { callExtendPackageLimit, asyncFlow } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

const extendPackageLimitFlow = asyncFlow(
  callExtendPackageLimit,
  extendPackageLimitResult,
  extendPackageLimitFail
);

function* extendPackageLimit({ packageCharsLimit, email }) {
  console.log('extendPackageLimit', packageCharsLimit, email);
  yield extendPackageLimitFlow({
    packageCharsLimit,
    email
  });
}

export default function* updateCardSaga() {
  yield takeEvery(
    EXTEND_PACKAGE_LIMIT_REQUEST,
    sagaErrorBoundaries(extendPackageLimit)
  );
}
