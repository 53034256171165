import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { logout } from '../actions/authActions';
import Storage from '../../storage';
import config from '../../config';

function callApiFunc(baseUrl) {
  return (endpoint, method = 'get', auth = true, multipart = false) => {
    const url = baseUrl + endpoint;
    return (payload = '') => {
      const user = Storage.getItem('NATURALTTS_USER', true);
      const multipartFormDataHeader = multipart
        ? {
            'Content-Type': 'multipart/form-data'
          }
        : {};
      const authHeader = auth
        ? {
            Authorization: 'Bearer ' + (user && user.token)
          }
        : {};
      const headers = {
        ...authHeader,
        ...multipartFormDataHeader
      };
      return method !== 'get' && payload
        ? axios({ method, url, data: payload, headers })
        : axios({ method, url, params: payload, headers });
    };
  };
}
const callApi = callApiFunc(config.API);

function asyncFlow(
  apiFn,
  successAction,
  errorAction,
  postEffect = null,
  preEffect = null,
  preEffectParams = null,
  errorPostEffect = null
) {
  return function*(payload = null) {
    try {
      if (preEffect && preEffectParams) yield call(preEffect, preEffectParams);
      console.log('sagaAsyncPayload', payload);
      const { data } = yield call(apiFn, payload);
      console.log('sagaAsyncResponse', data);

      if (data) yield put(successAction(data));
      if (postEffect) yield call(postEffect, data);
      return data;
    } catch (e) {
      console.log('sagaAsyncError', e);
      console.log('sagaAsyncErrorStatus', e.response);
      // TODO: rewrite like effect
      if (e.response.status === 401) {
        yield put(logout());
      }
      yield put(errorAction(e));
      if (errorPostEffect) {
        yield call(errorPostEffect);
      }
    }
  };
}

const callSignUp = callApi('/sign-up', 'post');
const callSignIn = callApi('/sign-in', 'post');
const callForgotPassword = callApi('/forgot-password', 'post');
const callResetPassword = callApi('/reset-password', 'post');
const callGoogleLogin = callApi('/google/sign-in', 'post');
const callUpdateUser = callApi('/user', 'put');
const callGetUser = callApi('/user', 'get');
const callConvertTextToSpeech = callApi('/converter', 'post');
const callFreeConvertTextToSpeech = callApi('/converter/free', 'post');
const callGetConverterHistory = callApi('/converter/history', 'get');
const callGetConverterTask = callApi('/converter/task', 'post');
const callAddSubscription = callApi('/payment/subscription', 'post');
const callUpdateCard = callApi('/payment/subscription/card', 'put');
const callCancelSubscription = callApi('/payment/subscription/cancel', 'post');
const callGetSubscription = callApi('/payment/subscription/');
const callRestoreSubscription = callApi(
  '/payment/subscription/restore',
  'post'
);
const callGetConverterVoices = callApi('/converter/voices');
const callGetConverterFreeVoices = callApi('/converter/free/voices');
const callSupportQuestion = callApi('/support/question', 'post');
const callExtendPackageLimit = callApi('/payment/package/limits', 'put');
const callUpgradeSubscription = callApi('/payment/subscription/upgrade', 'put');
const callParseDocument = callApi('/document/parse', 'post');
const callGetDocumentJob = callApi('/document/job');
const callApplyDiscount = callApi('/payment/subscription/discount', 'put');
const callSignUpStackSocial = callApi('/sign-up/stack-social', 'post');

export {
  callApi,
  callSignUp,
  callSignIn,
  callForgotPassword,
  callResetPassword,
  callUpdateUser,
  callGoogleLogin,
  callConvertTextToSpeech,
  callGetConverterHistory,
  callGetConverterTask,
  callAddSubscription,
  callFreeConvertTextToSpeech,
  callUpdateCard,
  callCancelSubscription,
  callGetSubscription,
  callRestoreSubscription,
  callGetConverterVoices,
  callGetConverterFreeVoices,
  callSupportQuestion,
  callExtendPackageLimit,
  callUpgradeSubscription,
  callParseDocument,
  callGetDocumentJob,
  callApplyDiscount,
  callGetUser,
  callSignUpStackSocial,
  asyncFlow
};
