import React from 'react';
import PropTypes from 'prop-types';

const EndMessageComponent = ({ message }) => (
  <p style={{ textAlign: 'center' }}>
    <b>{message}</b>
  </p>
);
EndMessageComponent.propTypes = {
  message: PropTypes.string.isRequired
};
export { EndMessageComponent };
