import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import PermanentDrawer from '../../ui/Drawer/PermanentDrawer';
import ExpansionPanel from '../../ui/ExpansionPanel/ExpansionPanel';
import { isFullAuthSelector } from '../../selectors/authSelector';

const useStyles = makeStyles(theme => {
  return {
    root: {
      flex: '1 1',
      marginTop: 90,
      padding: theme.spacing(8, 5, 1, 5)
    },
    titleContainer: {
      marginBottom: theme.spacing(10)
    },
    title: {
      width: '100%',
      padding: theme.spacing(4, 0)
    },
    description: {
      color: theme.palette.grey['600']
    },
    expansionPanel: {
      width: '100%',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
    }
  };
});

const containerGrid = {
  xs: 12,
  sm: 10,
  md: 11,
  lg: 9,
  xl: 8
};
const questions = [
  {
    title: 'How to start using Naturaltts?',
    text: `To get started with Naturaltts you will need to create an account. To do this, just click on the “Sign Up” button at the top right corner of the website. Here you must choose what type of account you want to register “free”, “personal” or “commercial” (Keep in mind, you will always have an ability to upgrade your account in the future in your User Panel). Then, fill in the required information and tick the Agreement checkbox. Click on the “Get Started” button. Congratulations! You can now use NaturallTTS Text To Speech Converter.`
  },
  {
    title: 'What is the cost of Naturaltts?',
    text: `We have three main pricing plans at Naturaltts:
    Free - completely free to use,
    Personal - $9.99/monthly,
    Commercial - $79.99/monthly.`
  },
  {
    title: 'What is the difference between the free and paid versions?',
    text: `The information about the difference between versions can be found at the Pricing section on landing page.`
  },
  {
    title: 'How do I upgrade my subscription?',
    text: `Go to the “Billing” page of your account. Below the “Upgrade Plan”  you’ll see the dropdown with available plans for the upgrade. Choose the one you need and fill the required card information. Then just click on the “Subscribe” button.`
  },
  {
    title: 'What are the renewal, cancellation, and refund policies?',
    text: `This information can be found at the appropriate pages: Terms, Refunds, Privacy.`
  },
  {
    title: 'I do not see my question here and I still need help!',
    text: `If you have any requests, please go to the Support page in your account and fill in the contact form.`
  },
  {
    title:
      'When the new subscription will start after I upgraded subscription?',
    text: `Your new subscription will start right from the moment when you upgraded the subscription. It will be the first day of your new subscription period.`
  }
];

const FAQ = ({ username, isAuth }) => {
  console.log('isAuth', isAuth);
  const classes = useStyles();
  return (
    <Grid container>
      {isAuth && <PermanentDrawer active="/FAQ" title={username} />}
      <Grid
        container
        item
        alignContent="flex-start"
        justify="center"
        wrap="wrap"
        className={classes.root}
        id="FAQ"
      >
        <Grid item container {...containerGrid}>
          <Grid
            item
            container
            alignContent="center"
            className={classes.titleContainer}
          >
            <Typography variant="h4" component="h1" className={classes.title}>
              FAQ
            </Typography>
            <Typography className={classes.description} align="left">
              If you don't understand something or you just need help, we are
              always here to help. Read our Frequently Asked Questions below:
            </Typography>
          </Grid>
          <Grid item container>
            {questions.map(({ title, text }, index) => {
              const key = `${title}-${index}`;
              return (
                <ExpansionPanel
                  id={key}
                  key={key}
                  title={title}
                  text={text}
                  className={classes.expansionPanel}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FAQ.propTypes = {
  username: PropTypes.string,
  isAuth: PropTypes.bool
};

export default connect(state => {
  return {
    isAuth: isFullAuthSelector(state),
    username: get(state, 'auth.user.username', '')
  };
})(FAQ);
