import { createSelector } from 'reselect';

const getVoices = state => state.converter.voices;
const getVoiceLanguage = state => state.converter.voiceLanguage;

const voicesSelector = createSelector(
  [getVoices, getVoiceLanguage],
  (voices, lang) => {
    return voices.filter(voice => voice.LanguageCode === lang);
  }
);

export default voicesSelector;
