import { takeEvery, call } from 'redux-saga/effects';

import {
  UPDATE_USER_REQUEST,
  updateUserResult,
  updateUserFail
} from '../../actions/authActions';
import { callUpdateUser, asyncFlow } from '../../api';
import Storage from '../../../storage';
import { sagaErrorBoundaries } from '../../utils';

function* updateUserEffect({ username }) {
  const user = yield call(Storage.getItem, 'NATURALTTS_USER', true);
  const updatedUser = {
    ...user,
    username
  };
  yield call(Storage.setItem, 'NATURALTTS_USER', updatedUser, true);
}

const updateUserFlow = asyncFlow(
  callUpdateUser,
  updateUserResult,
  updateUserFail,
  updateUserEffect
);

export default function* updateUserSaga() {
  yield takeEvery(UPDATE_USER_REQUEST, sagaErrorBoundaries(updateUserFlow));
}
