import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const getProperColor = (theme, variation) => {
  switch (variation) {
    case 'secondary':
      return {
        background: theme.palette.secondary.main,
        '&:hover': {
          background: theme.palette.secondary.dark
        }
      };
    case 'inverted':
      return {
        background: theme.palette.common.white,
        color: theme.palette.secondary.main,
        '&:hover': {
          background: theme.palette.secondary.main,
          color: theme.palette.common.white
        }
      };
    case 'danger':
      return {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.error.dark,
          color: theme.palette.common.white
        }
      };
    case 'default':
      return {};
    default:
      return { background: theme.palette.primary[variation] };
  }
};
const getProperSize = size => {
  switch (size) {
    case 'xs':
      return { width: 130, height: 40, borderRadius: 40 };
    case 's':
      return { width: 160, height: 50, borderRadius: 40 };
    case 'l':
      return { width: 175, height: 50, borderRadius: 50 };
    case 'll':
      return { width: 180, height: 60, borderRadius: 50 };
    case 'xl':
      return { width: 180, height: 50, borderRadius: 50 };
    case 'none':
      return {};
    default:
      return { width: 175, height: 50, borderRadius: 50 };
  }
};
const useStyles = makeStyles(theme => {
  return {
    root: ({ variation, size, uppercase, disabled }) => ({
      ...getProperColor(theme, variation),
      ...getProperSize(size),
      ...(disabled
        ? {
            background: `${theme.palette.grey['200']} !important`,
            color: theme.palette.action.disabled
          }
        : {}),
      textTransform: uppercase ? 'uppercase' : 'none',
      fontWeight: 700
    })
  };
});

function BaseButton(props) {
  const classes = useStyles(props);
  return (
    <Button
      variant={props.variant}
      color="primary"
      disabled={props.disabled}
      className={cx(props.className, classes.root)}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}

BaseButton.defaultProps = {
  variation: 'main',
  size: 'l',
  color: 'primary',
  uppercase: false,
  disabled: false,
  variant: 'contained'
};

BaseButton.propTypes = {
  variation: PropTypes.oneOf([
    'main',
    'light',
    'secondary',
    'inverted',
    'danger',
    'default'
  ]),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['xs', 's', 'l', 'll', 'xl', 'none']),
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  uppercase: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default React.memo(BaseButton);
