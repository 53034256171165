import get from 'lodash/get';

export const PARSE_DOCUMENT_REQUEST = 'PARSE_DOCUMENT_REQUEST';
export const PARSE_DOCUMENT_RESULT = 'PARSE_DOCUMENT_RESULT';
export const PARSE_DOCUMENT_FAIL = 'PARSE_DOCUMENT_FAIL';
export const CLEAR_PARSE_DOCUMENT_ERROR = 'CLEAR_PARSE_DOCUMENT_ERROR';
export const SET_PARSE_DOCUMENT_ERROR = 'SET_PARSE_DOCUMENT_ERROR';
export const START_DOCUMENT_JOB_POLLING = 'START_DOCUMENT_JOB_POLLING';
export const STOP_DOCUMENT_JOB_POLLING = 'STOP_DOCUMENT_JOB_POLLING';
export const FAIL_DOCUMENT_JOB_POLLING = 'FAIL_DOCUMENT_JOB_POLLING';
export const DOCUMENT_JOB_POLLING_RESULT = 'DOCUMENT_JOB_POLLING_RESULT';

export function parseDocumentRequest(file, extention, setParsedText) {
  console.log('parseDocumentRequestAct', file, extention, setParsedText);
  return {
    type: PARSE_DOCUMENT_REQUEST,
    loading: true,
    file,
    extention,
    setParsedText
  };
}

export function parseDocumentResult({ parsedText }) {
  return {
    type: PARSE_DOCUMENT_RESULT,
    loading: false,
    parsedText
  };
}

export function parseDocumentFail(error) {
  return {
    type: PARSE_DOCUMENT_FAIL,
    loading: false,
    error
  };
}

export function clearParseDocumentError() {
  return {
    type: CLEAR_PARSE_DOCUMENT_ERROR,
    error: ''
  };
}

export function setParseDocumentError(message) {
  return {
    type: SET_PARSE_DOCUMENT_ERROR,
    error: message
  };
}

export function startDocumentJobPolling({ JobId, setParsedText }) {
  return {
    type: START_DOCUMENT_JOB_POLLING,
    Id: JobId,
    setParsedText,
    loading: true
  };
}

export function stopDocumentJobPolling() {
  return {
    type: STOP_DOCUMENT_JOB_POLLING,
    loading: false
  };
}

export function failDocumentJobPolling(error) {
  return {
    type: FAIL_DOCUMENT_JOB_POLLING,
    loading: false,
    error
  };
}

export function documentJobPollingResult(data) {
  return {
    type: DOCUMENT_JOB_POLLING_RESULT,
    loading: false,
    parsedText: get(data, 'data.Job.text', '')
  };
}
