import { takeLatest } from 'redux-saga/effects';

import {
  APPLY_DISCOUNT_REQUEST,
  applyDiscountResult,
  applyDiscountFail
} from '../../actions/paymentActions';
import { asyncFlow, callApplyDiscount } from '../../api';
import { sagaErrorBoundaries } from '../../utils';

const applyDiscountFlow = asyncFlow(
  callApplyDiscount,
  applyDiscountResult,
  applyDiscountFail
);

function* applyDiscount({ discount }) {
  console.log('applyDiscount', discount);
  yield applyDiscountFlow({
    discount
  });
}

export default function* applyDiscountSaga() {
  yield takeLatest(APPLY_DISCOUNT_REQUEST, sagaErrorBoundaries(applyDiscount));
}
