import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { amber, green } from '@material-ui/core/colors';
import {
  SnackbarContent as MuiSnackbarContent,
  IconButton
} from '@material-ui/core';
import {
  Warning,
  Error as ErrorIcon,
  Info,
  Close,
  CheckCircle
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: ErrorIcon,
  info: Info
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const useSnckbarContentStyles = makeStyles(theme => ({
  message: {
    flex: '1 1',
    '& MuiSvgIcon-root': {
      marginRight: theme.spacing(2)
    }
  },
  action: {
    flex: '0 0 auto'
  }
}));

function SnackbarContent(props) {
  const classes = useStyles();
  const snackbarContentClasses = useSnckbarContentStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <MuiSnackbarContent
      className={cx(classes[variant], className)}
      classes={snackbarContentClasses}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

export default SnackbarContent;
