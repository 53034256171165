import React from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import BaseButton from '../../ui/Button/BaseButton';
import BasicCarousel from '../../ui/Carousel/BasicCarousel';
import AnchorLink from '../../ui/Link/AnchorLink';
import { PICTURES_BUCKET } from '../../config/buckets';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      marginTop: 0,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      height: 850,
      [theme.breakpoints.up('md')]: {
        marginTop: 90
      },
      overflow: 'hidden'
    },
    waveLayer: {
      position: 'absolute',
      height: 220,
      left: 0,
      bottom: 0,
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        width: '100%'
      }
    },
    opacityOverlay: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      background: theme.palette.primary.base,
      opacity: 0.8
    },
    heroSection: {
      zIndex: 1
    },
    titleSection: {
      '& > h1, h6, button': {
        margin: theme.spacing(2, 0)
      },
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '0 30px'
      }
    },
    descriptionStatsSection: {
      '& > div': {
        width: 200
      }
    },
    rootHeroImage: {
      width: 'auto',
      height: '100%',
      top: 0,
      position: 'absolute',
      overflow: 'hidden',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        height: 'auto'
      }
    },
    heroImage: {
      width: 'auto',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        height: 'auto'
      }
    },
    descriptionItem: {
      '& h3::after': {
        content: "''",
        display: 'block',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: 'white'
      }
    },
    image: {
      maxWidth: '100%',
      width: 'auto'
    }
  };
});

const content = [
  { label: '61+', text: 'Natural Sounding Voices' },
  { label: '6', text: 'Most Popular Languages' },
  {
    label: '$',
    text: `The most affordable prices on the market`
  }
];

function TopHeroSection({ isAuth }) {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid className={classes.root}>
      <Grid container justify="center" className={classes.heroSection}>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.titleSection}
        >
          <Typography variant="h3" component="h1" color="textSecondary">
            Free Online Text to Speech Converter
          </Typography>
          <Typography variant="subtitle1" component="h3" color="textSecondary">
            Naturaltts.com provides the best online text to speech converter
            with a free Mp3 download feature. Try out our free version to be
            sure for yourself!
          </Typography>
          {isAuth ? (
            <AnchorLink to="#pricing" offset={0}>
              <BaseButton variation="inverted">Upgrade your plan</BaseButton>
            </AnchorLink>
          ) : (
            <a
              href="https://rythmex.com/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BaseButton variation="inverted">Try for FREE</BaseButton>
            </a>
          )}
        </Grid>
        {isMobile ? (
          <BasicCarousel steps={content}>
            {(steps, activeStep) => {
              return steps.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Grid className={classes.descriptionItem}>
                      <Typography variant="h3" color="textSecondary">
                        {step.label}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {step.text}
                      </Typography>
                    </Grid>
                  ) : null}
                </div>
              ));
            }}
          </BasicCarousel>
        ) : (
          <Grid
            item
            container
            justify="space-around"
            className={classes.descriptionStatsSection}
          >
            {content.map(item => {
              return (
                <Grid item key={item.label} className={classes.descriptionItem}>
                  <Typography variant="h3" color="textSecondary">
                    {item.label}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {item.text}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
      <picture className={classes.rootHeroImage}>
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithNotes.d2000x1335.jpg`}
          media="(min-width: 1919px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithNotes.d1440x961.jpg`}
          media="(min-width: 1440px) and (max-width: 1919px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithNotes.d1280x855.jpg`}
          media="(min-width: 1280px) and (max-width: 1439px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithNotes.d480x320.jpg`}
          media="(min-width: 480px) and (max-width: 1279px)"
        />
        <source
          srcSet={`${PICTURES_BUCKET}/homepage/manWithNotes.d887x1229.jpg`}
          media="(max-width: 479px)"
        />
        <img
          className={classes.heroImage}
          src={`${PICTURES_BUCKET}/homepage/manWithNotes.d1440x961.jpg`}
          alt="Top Hero Naturaltts"
        />
      </picture>
      <img
        className={classes.waveLayer}
        src={`${PICTURES_BUCKET}/wave_1.png`}
        alt="Top Hero Naturaltts Wave"
      />
      <div className={classes.opacityOverlay} />
    </Grid>
  );
}

TopHeroSection.propTypes = {
  isAuth: PropTypes.bool.isRequired
};

export default TopHeroSection;
