import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  dropzoneArea: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      display: 'flex',
      width: 700,
      height: 400,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
      zIndex: 8,
      top: '30%'
    },
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dropzoneInnerArea: {
    border: '5px dashed white',
    width: 550,
    height: 300
  },
  title: {
    color: 'white',
    textTransform: 'uppercase'
  },
  subtitle: {
    color: 'white',
    textDecoration: 'underline'
  }
}));

// const exceedLimitError = extention =>
//   `Your ${extention} file exceeds upload limit`;

// const FILE_UPLOAD_LIMIT = {
//   pdf: 50,
//   png: 4,
//   jpg: 4
// };
function Dropzone({
  children,
  className = '',
  parseDocument,
  setParseDocumentResult,
  closeDropzone,
  setParseDocumentError
}) {
  const classes = useStyles();
  const onDrop = useCallback(
    acceptedFiles => {
      const uploadedFile = acceptedFiles[0];
      if (uploadedFile) {
        const extention = uploadedFile.name.substring(
          uploadedFile.name.lastIndexOf('.') + 1
        );
        console.log('file', uploadedFile);
        console.log('extention', extention);
        const reader = new FileReader();
        //const fileSize = uploadedFile.size / 1024 / 1024;
        reader.onerror = () => setParseDocumentError('Read file error');

        switch (extention) {
          case 'txt':
            reader.onload = () => {
              // Do whatever you want with the file contents
              const text = reader.result;
              console.log('text', text);
              setParseDocumentResult(text);
            };
            reader.readAsText(uploadedFile);
            break;
          // case 'pdf':
          // case 'jpg':
          // case 'png':
          //   reader.onload = () => {
          //     parseDocument(
          //       reader.result.replace(/^data:.+;base64,/, ''),
          //       extention,
          //       setParseDocumentResult
          //     );
          //   };
          //   if (fileSize < FILE_UPLOAD_LIMIT[extention]) {
          //     reader.readAsDataURL(uploadedFile);
          //   } else {
          //     setParseDocumentError(exceedLimitError(extention));
          //   }
          //   break;
          default:
            setParseDocumentError('This is unsupported document format');
            break;
        }
      }
      console.log('acceptedFiles', acceptedFiles);
      closeDropzone();
    },
    [
      closeDropzone,
      // parseDocument,
      setParseDocumentResult,
      setParseDocumentError
    ]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  console.log('getRootProps', getRootProps());
  console.log('getInputProps', getInputProps());
  return (
    <Grid
      container
      item
      alignItems="center"
      justify="center"
      {...getRootProps()}
      id="dropzone"
      className={classes.dropzoneArea}
    >
      <input {...getInputProps()} />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justify="space-evenly"
        className={cx(className, classes.dropzoneInnerArea)}
      >
        {isDragActive ? (
          <Typography variant="h5" className={classes.title}>
            Drop your files here
          </Typography>
        ) : (
          <>
            <Typography variant="h5" className={classes.title}>
              Drop your files here
            </Typography>
            <Typography className={classes.subtitle}>
              or click to select files
            </Typography>
          </>
        )}
      </Grid>
      {children}
    </Grid>
  );
}
Dropzone.propTypes = {
  children: PropTypes.any,
  parseDocument: PropTypes.func,
  setParseDocumentResult: PropTypes.func,
  className: PropTypes.any,
  closeDropzone: PropTypes.func,
  setParseDocumentError: PropTypes.func
};

export default Dropzone;
