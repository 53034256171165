import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import Button from '../Button/BaseButton';

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleAgree: PropTypes.func.isRequired
};

export default function Dialog({
  open,
  handleClose,
  title,
  description,
  handleAgree
}) {
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          color="default"
          variation="danger"
          size="none"
        >
          No
        </Button>
        <Button onClick={handleAgree} size="none" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
