import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => {
  return {
    root: {
      '&::before': {
        display: 'none'
      },
      '&:first-child': {
        borderRadius: 20
      },
      '&:last-child': {
        borderRadius: 20
      },
      borderRadius: 20,
      backgroundColor: theme.palette.grey['100']
    },
    text: {
      color: theme.palette.grey['600']
    }
  };
});

const ExpansionPanel = ({ id, title, text, className = '' }) => {
  const classes = useStyles();
  const [opened, setOpen] = useState(false);
  const setOpenHandler = () => setOpen(val => !val);

  return (
    <MuiExpansionPanel className={cx(classes.root, className)}>
      <ExpansionPanelSummary
        onClick={setOpenHandler}
        expandIcon={opened ? <Remove /> : <Add />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography className={classes.text}>{text}</Typography>
      </ExpansionPanelDetails>
    </MuiExpansionPanel>
  );
};

ExpansionPanel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ExpansionPanel;
