import { combineReducers } from 'redux';

import auth from './auth';
import converter from './converter';
import support from './support';
import document from './document';
import websocket from './websocket';

export default combineReducers({
  auth,
  converter,
  support,
  document,
  websocket
});
