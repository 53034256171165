import React from 'react';
import { List, IconButton, Divider, SwipeableDrawer } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex'
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    drawer: {
      width: 300,
      flexShrink: 0
    },
    drawerPaper: {
      width: 300
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3, 8),
      justifyContent: 'flex-end'
    },
    list: {
      padding: 0
    }
  };
});

const SideDrawer = ({
  children,
  handleDrawerOpen,
  handleDrawerClose,
  open
}) => {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      className={classes.drawer}
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <List className={classes.list}>{children}</List>
    </SwipeableDrawer>
  );
};

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default React.memo(SideDrawer);
