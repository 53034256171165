import { takeLatest, call } from 'redux-saga/effects';

import documentJobPollingSaga from './documentJobPollingSaga';
import { sagaErrorBoundaries } from '../../utils';
import {
  PARSE_DOCUMENT_REQUEST,
  parseDocumentResult,
  parseDocumentFail
} from '../../actions/documentActions';
import { asyncFlow, callParseDocument } from '../../api';

const parseDocumentFlow = asyncFlow(
  callParseDocument,
  parseDocumentResult,
  parseDocumentFail
);

function* parseDocument({ file, extention, setParsedText }) {
  console.log('file12', file, extention);
  const data = yield parseDocumentFlow({ file, extention });
  const { parsedText, JobId } = data || {};
  console.log('parsedText', parsedText);
  console.log('JobId', JobId);
  if (parsedText) {
    yield setParsedText(parsedText);
  } else if (JobId) {
    console.log('Yes');
    yield call(documentJobPollingSaga.startPolling, { JobId, setParsedText });
  }
}

export default function* parseDocumentSaga() {
  yield takeLatest(PARSE_DOCUMENT_REQUEST, sagaErrorBoundaries(parseDocument));
}
