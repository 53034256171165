import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import {
  supportQuestionRequest,
  clearSupportQuestionRequestError,
  clearSupportQuestionSuccess
} from '../../redux/actions/supportActions';
import FormPage from '../../ui/FormPage/FormPage';
import SupportForm from './SupportForm';
import PermanentDrawer from '../../ui/Drawer/PermanentDrawer';
import { isAuthSelector } from '../../selectors/authSelector';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      flex: '1 1'
    },
    innerFormWrapper: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        height: 600
      },
      [theme.breakpoints.up('md')]: {
        height: 700
      }
    },
    formWrapper: {
      marginBottom: theme.spacing(1)
    },
    titleSection: {
      flexDirection: 'column'
    }
  };
});

const Support = ({
  isAuth,
  clearSupportQuestionRequestError,
  clearSupportQuestionSuccess,
  handleSubmit,
  errorMessage,
  successMessage,
  user,
  loading
}) => {
  const classes = useStyles();
  const supportFormProps = {
    isAuth,
    errorMessage,
    successMessage,
    clearSupportQuestionRequestError,
    clearSupportQuestionSuccess,
    loading,
    handleSubmit
  };
  const formGrid = isAuth
    ? {
        md: 8,
        lg: 6
      }
    : {
        md: 6,
        lg: 4
      };
  return (
    <Grid container>
      {isAuth && <PermanentDrawer active="/support" title={user.username} />}
      <Grid className={classes.formContainer} item>
        <FormPage
          title="Support"
          subtitle="Ask your question and we will answer you via the email"
          {...formGrid}
          wrapperClassname={classes.formWrapper}
          innerWrapperClassName={classes.innerFormWrapper}
          titleSectionClassame={classes.titleSection}
        >
          <SupportForm {...supportFormProps} />
        </FormPage>
      </Grid>
    </Grid>
  );
};

const SupportWithFormik = withFormik({
  mapPropsToValues: ({ user }) => ({
    name: user ? user.username : '',
    email: user ? user.email : '',
    question: ''
  }),
  handleSubmit: (
    { question },
    { props: { supportQuestionRequest }, resetForm }
  ) => {
    supportQuestionRequest(question);
    resetForm();
  },
  validationSchema: Yup.object().shape({
    question: Yup.string()
      .min(5, "Question couldn't be such short")
      .max(300, "Question couldn't be more than 300 chars")
      .required('Required')
  })
})(Support);

Support.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.any,
  successMessage: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  clearSupportQuestionRequestError: PropTypes.func.isRequired,
  clearSupportQuestionSuccess: PropTypes.func.isRequired,
  user: PropTypes.any
};

export default connect(
  state => {
    console.log('state.support', state.support);
    return {
      user: state.auth.user,
      loading: state.support.loading,
      errorMessage: state.support.error,
      successMessage: state.support.success,
      isAuth: isAuthSelector(state)
    };
  },
  {
    supportQuestionRequest,
    clearSupportQuestionRequestError,
    clearSupportQuestionSuccess
  }
)(SupportWithFormik);
