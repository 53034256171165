import { takeLatest, put } from 'redux-saga/effects';

import {
  CONVERTER_GET_VOICES_REQUEST,
  converterGetVoicesResult,
  converterGetVoicesFail,
  changeFreeVoicesFlag,
  changeVoiceLanguage
} from '../../actions/converterActions';
import {
  callGetConverterVoices,
  callGetConverterFreeVoices,
  asyncFlow
} from '../../api';
import { sagaErrorBoundaries } from '../../utils';

const convertGetVoicesFlow = asyncFlow(
  callGetConverterVoices,
  converterGetVoicesResult,
  converterGetVoicesFail
);

const convertGetFreeVoicesFlow = asyncFlow(
  callGetConverterFreeVoices,
  converterGetVoicesResult,
  converterGetVoicesFail
);

function* convertGetVoices({ free }) {
  console.log('getVoicesSaga', free);
  if (free) {
    yield convertGetFreeVoicesFlow();
    yield put(changeFreeVoicesFlag(true));
    yield put(changeVoiceLanguage('en-US'));
  } else {
    yield convertGetVoicesFlow();
    yield put(changeFreeVoicesFlag(false));
    yield put(changeVoiceLanguage('en-US'));
  }
}

export default function* converterHistorySaga() {
  yield takeLatest(
    CONVERTER_GET_VOICES_REQUEST,
    sagaErrorBoundaries(convertGetVoices)
  );
}
