import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useChipStyles = makeStyles(theme => {
  return {
    root: {
      marginLeft: theme.spacing(1),
      marginTop: '2px',
      height: '20px'
    },
    isActiveCl: {
      position: 'absolute',
      zIndex: 1,
      left: '104px',
      top: '-9px',
      display: 'block',
      paddingTop: '2px',
      marginLeft: 0,
      marginTop: 0
    }
  };
});

const SelectBadge = memo(function SelectBadge({
  supportedEngines,
  isActive,
  isActiveOpened
}) {
  const classes = useChipStyles();
  const chipProps = {
    label: isActiveOpened
      ? 'selected'
      : supportedEngines
      ? 'neural'
      : 'standard',
    color: isActiveOpened
      ? 'default'
      : supportedEngines
      ? 'primary'
      : 'secondary',
    className: cx(classes.root, { [classes.isActiveCl]: isActive }),
    size: 'small'
  };
  return <Chip {...chipProps} />;
});

SelectBadge.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isActiveOpened: PropTypes.bool.isRequired,
  supportedEngines: PropTypes.any
};

export default SelectBadge;
