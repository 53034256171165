const displayErrorMessage = (errors, touched, fieldName) =>
  errors[fieldName] && touched[fieldName] ? errors[fieldName] : '';

const isFieldError = (errors, touched, fieldName) =>
  !!errors[fieldName] && !!touched[fieldName];

const onFieldChangeFunc = (setField, setTouched) => (fieldName, value) => {
  setField(fieldName, value);
  setTouched(fieldName);
};
const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const getSafe = (fn, defaultVal) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export {
  displayErrorMessage,
  isFieldError,
  onFieldChangeFunc,
  isEmpty,
  getSafe
};
