import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import {
  useSelectContainerComponentStyles,
  useSelectComponentStyles,
  StyledOutlinedInput
} from './styledComponents';

function SelectVoiceLanguageField({ voiceLanguages, value, onChangeLang }) {
  const classes = useSelectContainerComponentStyles();
  const selectStyles = useSelectComponentStyles();
  return (
    <FormControl fullWidth={true} className={classes.root}>
      <InputLabel
        htmlFor="voice-language-helper"
        variant="outlined"
        className={classes.inputLabel}
      >
        Choose Language
      </InputLabel>
      <Select
        value={value}
        onChange={event => onChangeLang(event.target.value)}
        variant="outlined"
        classes={selectStyles}
        input={
          <StyledOutlinedInput
            name="voice-language"
            id="voice-language-helper"
          />
        }
      >
        {voiceLanguages.map(lang => {
          return (
            <MenuItem value={lang.LanguageCode} key={lang.LanguageCode}>
              {lang.LanguageName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectVoiceLanguageField.propTypes = {
  voiceLanguages: PropTypes.array,
  value: PropTypes.any,
  onChangeLang: PropTypes.func
};

export default SelectVoiceLanguageField;
