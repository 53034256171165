export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_RESULT = 'SIGNUP_RESULT';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_RESULT = 'SIGNIN_RESULT';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_RESULT = 'GOOGLE_LOGIN_RESULT';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESULT = 'UPDATE_USER_RESULT';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_RESULT = 'FORGOT_PASSWORD_RESULT';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESULT = 'RESET_PASSWORD_RESULT';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const CLEAR_AUTH_SUCCESS = 'CLEAR_AUTH_SUCCESS';
export const CLEAR_AUTH_PAYMENT_ERROR = 'CLEAR_AUTH_PAYMENT_ERROR';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_RESULT = 'GET_USER_RESULT';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const COMPLETE_SIGNUP_REQUEST = 'COMPLETE_SIGNUP_REQUEST';
export const COMPLETE_SIGNUP_RESULT = 'COMPLETE_SIGNUP_RESULT';
export const COMPLETE_SIGNUP_FAIL = 'COMPLETE_SIGNUP_FAIL';
export const CREATED_USER_SUBSCRIPTION = 'CREATED_USER_SUBSCRIPTION';
export const START_3D_SECURE = 'START_3D_SECURE';
export const FAILED_3D_SECURE = 'FAILED_3D_SECURE';
export const SIGNUP_STACK_SOCIAL_REQUEST = 'SIGNUP_STACK_SOCIAL_REQUEST';
export const SIGNUP_STACK_SOCIAL_RESULT = 'SIGNUP_STACK_SOCIAL_RESULT';
export const SIGNUP_STACK_SOCIAL_FAIL = 'SIGNUP_STACK_SOCIAL_FAIL';

export function updateUserRequest({
  username,
  oldPassword,
  newPassword,
  email,
  changePassword
}) {
  return {
    type: UPDATE_USER_REQUEST,
    loading: true,
    username,
    oldPassword,
    newPassword,
    changePassword,
    email
  };
}

export function updateUserResult({ username, message }) {
  return {
    type: UPDATE_USER_RESULT,
    loading: false,
    success: message,
    username
  };
}

export function updateUserFail(error) {
  return {
    type: UPDATE_USER_FAIL,
    error,
    loading: false
  };
}

export function signUpRequest(
  name,
  email,
  password,
  plan = 'free',
  paymentMethod = null,
  stripe = null,
  userSubscriptionId = null
) {
  console.log('planRequest', plan);
  return {
    type: SIGNUP_REQUEST,
    loading: true,
    name,
    email,
    password,
    plan,
    paymentMethod,
    stripe,
    userSubscriptionId
  };
}

export function signUpResult(data) {
  return {
    type: SIGNUP_RESULT,
    loading: false,
    user: data.user,
    limits: data.limits
  };
}

export function signUpFail(error) {
  return {
    type: SIGNUP_FAIL,
    loading: false,
    error
  };
}

export function signUpStackSocialRequest(name, email, password, code) {
  return {
    type: SIGNUP_STACK_SOCIAL_REQUEST,
    loading: true,
    name,
    email,
    password,
    code
  };
}

export function signUpStackSocialResult(data) {
  return {
    type: SIGNUP_STACK_SOCIAL_RESULT,
    loading: false,
    user: data.user,
    limits: data.limits
  };
}

export function signUpStackSocialFail(error) {
  return {
    type: SIGNUP_STACK_SOCIAL_FAIL,
    loading: false,
    error
  };
}

export function completeSignUpRequest({
  name,
  email,
  password,
  plan,
  userSubscriptionId
}) {
  console.log('COMPLETE_SIGNUP_REQUEST', plan);
  return {
    type: COMPLETE_SIGNUP_REQUEST,
    loading: true,
    name,
    email,
    password,
    plan,
    userSubscriptionId
  };
}

export function completeSignUpResult(data) {
  return {
    type: COMPLETE_SIGNUP_RESULT,
    loading: false,
    user: data.user,
    limits: data.limits
  };
}

export function start3DSecure() {
  return {
    type: START_3D_SECURE,
    loading: true
  };
}

export function failed3DSecure(id, error) {
  return {
    type: FAILED_3D_SECURE,
    userSubscriptionId: id,
    error:
      error && error.type === 'card_error' && error.message
        ? error.message
        : 'Failed to make payment',
    loading: false
  };
}

export function completeSignUpFail(error) {
  return {
    type: COMPLETE_SIGNUP_FAIL,
    loading: false,
    error
  };
}

export function signInRequest(email, password) {
  return {
    type: SIGNIN_REQUEST,
    loading: true,
    email,
    password
  };
}

export function signInResult(data) {
  return {
    type: SIGNIN_RESULT,
    loading: false,
    user: data.user,
    limits: data.limits
  };
}

export function signInFail(error) {
  return {
    type: SIGNIN_FAIL,
    loading: false,
    error
  };
}

export function googleLoginRequest(tokenId) {
  return {
    type: GOOGLE_LOGIN_REQUEST,
    loading: true,
    tokenId
  };
}

export function googleLoginResult(data) {
  return {
    type: GOOGLE_LOGIN_RESULT,
    loading: false,
    user: data.user
  };
}

export function googleLoginFail() {
  return {
    type: GOOGLE_LOGIN_FAIL,
    loading: false
  };
}

export function forgotPasswordRequest(email) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    loading: true,
    email
  };
}

export function forgotPasswordResult() {
  return {
    type: FORGOT_PASSWORD_RESULT,
    success: 'The link with reset password was successfully sent on your email',
    loading: false
  };
}

export function forgotPasswordFail(error) {
  return {
    type: FORGOT_PASSWORD_FAIL,
    loading: false,
    error
  };
}

export function resetPasswordRequest(password, token) {
  return {
    type: RESET_PASSWORD_REQUEST,
    loading: true,
    password,
    token
  };
}

export function resetPasswordResult({ message }) {
  return {
    type: RESET_PASSWORD_RESULT,
    loading: false,
    message
  };
}

export function resetPasswordFail(error) {
  return {
    type: RESET_PASSWORD_FAIL,
    loading: false,
    error
  };
}

export function getUserRequest(email) {
  return {
    type: GET_USER_REQUEST,
    loading: true,
    email
  };
}

export function getUserResult({ user = {} }) {
  return {
    type: GET_USER_RESULT,
    loading: false,
    user
  };
}

export function getUserFail(error) {
  return {
    type: GET_USER_FAIL,
    loading: false,
    error
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR
  };
}

export function clearPaymentError() {
  return {
    type: CLEAR_AUTH_PAYMENT_ERROR
  };
}

export function clearAuthSuccess() {
  return {
    type: CLEAR_AUTH_SUCCESS
  };
}

export function logout() {
  return {
    type: LOGOUT,
    user: null,
    token: null
  };
}
