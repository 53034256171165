import { takeEvery, call } from 'redux-saga/effects';

import {
  GET_USER_REQUEST,
  getUserResult,
  getUserFail
} from '../../actions/authActions';
import { callGetUser, asyncFlow } from '../../api';
import Storage from '../../../storage';
import { sagaErrorBoundaries } from '../../utils';

function* confirmationUserEffect({ user: receivedUser }) {
  const user = yield call(Storage.getItem, 'NATURALTTS_USER', true);
  const updatedUser = {
    ...user,
    ...receivedUser
  };
  yield call(Storage.setItem, 'NATURALTTS_USER', updatedUser, true);
}

const getUserFlow = asyncFlow(
  callGetUser,
  getUserResult,
  getUserFail,
  confirmationUserEffect
);

export default function* confirmationUserSaga() {
  yield takeEvery(GET_USER_REQUEST, sagaErrorBoundaries(getUserFlow));
}
