import React from 'react';
import PropTypes from 'prop-types';
import { MobileStepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import cx from 'classnames';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStepperStyles = makeStyles({
  root: {
    background: 'transparent',
    justifyContent: 'center'
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '400px',
    padding: '0 30px'
  }
}));

function BasicCarousel({ steps, children, className = {} }) {
  const classes = useStyles();
  const stepperClasses = useStepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <div className={cx(classes.root, className.root)}>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {children(steps, activeStep)}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        classes={{
          root: cx(stepperClasses.root, className.stepperRoot),
          dots: className.dots
        }}
        activeStep={activeStep}
      />
    </div>
  );
}

BasicCarousel.propTypes = {
  steps: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  className: PropTypes.any
};

export default BasicCarousel;
