import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useGoogleLogout } from 'react-google-login';

import { PICTURES_BUCKET } from '../../config/buckets';
import AnchorLink from '../Link/AnchorLink';
import NavbarLink from '../Link/Link';
import BaseButton from '../Button/BaseButton';

const useTopNavbarStyles = makeStyles(theme => {
  return {
    toolbar: {
      minHeight: 90,
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 5),
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        margin: theme.spacing(0, 12)
      },
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 15)
      }
    },
    navbarLinks: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    },
    anchorLinks: {
      display: 'flex',
      justifyContent: 'center',
      flex: '1 1 auto',
      textAlign: 'center',
      '& a': {
        padding: theme.spacing(0, 4)
      }
    },
    menuButton: {
      color: theme.palette.primary.main
    },
    menuIcon: {
      width: 30,
      height: 30
    },
    link: {
      textDecoration: 'none',
      color: 'inherit'
    },
    navLinkWithSpacing: {
      margin: theme.spacing(0, 4)
    },
    navLink: {
      position: 'relative',
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        bottom: theme.spacing(-2),
        height: theme.spacing(1) - 1,
        left: 0,
        right: '100%'
      },
      '&:hover': {
        '&::after': {
          right: 0,
          transition: 'right 300ms linear'
        }
      }
    },
    logo: {
      width: 50,
      [theme.breakpoints.up('md')]: {
        width: 210
      }
    }
  };
});

const TopNavbar = props => {
  const theme = useTheme();
  const classes = useTopNavbarStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { signOut } = useGoogleLogout({
    onFailure: any => console.log('googleLogOutFailer', any),
    clientId: process.env.REACT_APP_GOOGLE_CONSUMER_KEY,
    cookiePolicy: 'single_host_origin',
    onLogoutSuccess: any => console.log('googleLogOutSuccesss', any),
    jsSrc: 'https://apis.google.com/js/api.js'
  });
  const logOutHandler = () => {
    signOut();
    props.logout();
  };
  return (
    <AppBar position="fixed" color="default">
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={classes.link}>
          <img
            src={
              isMobile
                ? `${PICTURES_BUCKET}/smallNaturalttsLogo.svg`
                : `${PICTURES_BUCKET}/originalNaturalttsLogo.svg`
            }
            alt="Naturaltts logo"
            className={classes.logo}
          />
        </Link>
        <Grid className={classes.navbarLinks}>
          {props.isAnchorLinks && (
            <Grid className={classes.anchorLinks}>
              <AnchorLink to="#home" className={classes.navLink}>
                Home
              </AnchorLink>
              <AnchorLink to="#voices" className={classes.navLink}>
                Voices
              </AnchorLink>
              <AnchorLink to="#pricing" className={classes.navLink}>
                Pricing
              </AnchorLink>
              <AnchorLink to="#why-us" className={classes.navLink}>
                About Us
              </AnchorLink>
            </Grid>
          )}
          {props.isAnchorLinks ? (
            <AnchorLink to="/" offset={0}>
              <BaseButton size="xl">Try for FREE</BaseButton>
            </AnchorLink>
          ) : (
            <a
              href="https://rythmex.com/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BaseButton size="xl">Try for FREE</BaseButton>
            </a>
          )}
          {props.isAuth ? (
            <>
              <NavbarLink
                to="/convert"
                active={props.location.pathname === '/profile'}
                className={cx(classes.navLinkWithSpacing, classes.navLink)}
              >
                Dashboard
              </NavbarLink>
              <NavbarLink
                to="/logout"
                className={classes.navLink}
                onClick={logOutHandler}
                disabled={true}
              >
                Logout
              </NavbarLink>
            </>
          ) : (
            <Grid>
              {/* Standard anchor tag for external Sign In link */}
              <a
                href="https://rythmex.com/register" // replace with your external sign-in URL
                className={cx(classes.navLinkWithSpacing, classes.navLink)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign In
              </a>

              {/* Standard anchor tag for external Sign Up link */}
              <a
                href="https://rythmex.com/register" // replace with your external sign-up URL
                className={classes.navLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign Up
              </a>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={props.toggleDrawer}
          >
            <Menu className={classes.menuIcon} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  isAnchorLinks: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
  logout: PropTypes.func
};

export default TopNavbar;
