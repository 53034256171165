import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(4)
  },
  loader: {
    color: theme.palette.grey['500']
  }
}));

const CircularLoader = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <CircularProgress className={classes.loader} />
    </Grid>
  );
};

export default CircularLoader;
