import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  isAuthSelector,
  isConfirmedSelector,
  isPaidSelector
} from '../../selectors/authSelector';

const confirmationRoutes = ['/confirmation', '/confirmed'];
const paymentRoutes = ['/update-payment'];

function ProtectedRoute({
  component: Component,
  isAuth,
  isConfirmed,
  isPaid,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        console.log('protectedeRoute', props.location.pathname);
        return isAuth ? (
          isConfirmed ||
          confirmationRoutes.includes(props.location.pathname) ? (
            isPaid || paymentRoutes.includes(props.location.pathname) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/update-payment" />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/confirmation',
                state: { validRedirect: true }
              }}
            />
          )
        ) : (
          <Redirect to="/sign-in" />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
  isAuth: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  isPaid: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    isAuth: isAuthSelector(state),
    isConfirmed: isConfirmedSelector(state),
    isPaid: isPaidSelector(state)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ProtectedRoute)
);
