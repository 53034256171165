import moment from 'moment-timezone';

import {
  SIGNUP_REQUEST,
  SIGNUP_RESULT,
  SIGNUP_FAIL,
  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_RESULT,
  COMPLETE_SIGNUP_FAIL,
  SIGNIN_REQUEST,
  SIGNIN_RESULT,
  SIGNIN_FAIL,
  LOGOUT,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_RESULT,
  GOOGLE_LOGIN_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESULT,
  UPDATE_USER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESULT,
  FORGOT_PASSWORD_FAIL,
  CLEAR_AUTH_ERROR,
  CLEAR_AUTH_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESULT,
  RESET_PASSWORD_FAIL,
  CLEAR_AUTH_PAYMENT_ERROR,
  GET_USER_RESULT,
  FAILED_3D_SECURE,
  START_3D_SECURE,
  SIGNUP_STACK_SOCIAL_REQUEST,
  SIGNUP_STACK_SOCIAL_RESULT,
  SIGNUP_STACK_SOCIAL_FAIL
} from '../actions/authActions';
// import {
//   ADD_PAYMENT_SUBSCRIPTION_REQUEST,
//   ADD_PAYMENT_SUBSCRIPTION_RESULT,
//   ADD_PAYMENT_SUBSCRIPTION_FAIL
// } from '../actions/paymentActions';
import Storage from '../../storage';
import { getErrorMessage } from '../utils';
import payment from './payment';

const initialState = {
  loading: false,
  user: Storage.getItem('NATURALTTS_USER', true),
  error: null,
  success: null,
  limits: {
    packageCharsLimit: 0,
    charsLimit: 0
  },
  timezone: moment.tz.guess(true)
};

export default function reducer(state = initialState, action = {}) {
  console.log('Auth action:', action);
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case SIGNUP_RESULT:
      return {
        ...state,
        user: action.user,
        loading: action.loading
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    case COMPLETE_SIGNUP_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case COMPLETE_SIGNUP_RESULT:
      return {
        ...state,
        user: action.user,
        loading: action.loading
      };
    case COMPLETE_SIGNUP_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    case SIGNUP_STACK_SOCIAL_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case SIGNUP_STACK_SOCIAL_RESULT:
      return {
        ...state,
        user: action.user,
        loading: action.loading
      };
    case SIGNUP_STACK_SOCIAL_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    case SIGNIN_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case SIGNIN_RESULT:
      return {
        ...state,
        user: action.user,
        limits: action.limits,
        loading: action.loading
      };
    case SIGNIN_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    // case ADD_PAYMENT_SUBSCRIPTION_REQUEST:
    //   console.log('ADD_PAYMENT_SUBSCRIPTION_REQUEST', action);
    //   return {
    //     ...state,
    //     loading: action.loading
    //   };
    // case ADD_PAYMENT_SUBSCRIPTION_RESULT:
    //   console.log('ADD_PAYMENT_SUBSCRIPTION_RESULT', action);
    //   console.log('ADD_PAYMENT_SUBSCRIPTION_RESULT1', state);
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       ...action.subscription
    //     },
    //     limits: {
    //       ...state.limits,
    //       ...action.limits
    //     },
    //     loading: action.loading
    //   };
    // case ADD_PAYMENT_SUBSCRIPTION_FAIL:
    //   console.log('ADD_PAYMENT_SUBSCRIPTION_FAIL', action);
    //   return {
    //     ...state,
    //     loading: action.loading,
    //     paymentError: getErrorMessage(action.error)
    //   };
    case GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case GOOGLE_LOGIN_RESULT:
      return {
        ...state,
        user: action.user,
        loading: action.loading
      };
    case GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        loading: action.loading
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case UPDATE_USER_RESULT:
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username
        },
        success: action.success,
        loading: action.loading
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: getErrorMessage(action.error),
        loading: action.loading
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case FORGOT_PASSWORD_RESULT:
      return {
        ...state,
        loading: action.loading,
        success: action.success
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case RESET_PASSWORD_RESULT:
      return {
        ...state,
        loading: action.loading,
        success: action.message
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: action.loading
      };
    case GET_USER_RESULT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        }
      };
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: ''
      };
    case CLEAR_AUTH_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: ''
      };
    case CLEAR_AUTH_SUCCESS:
      return {
        ...state,
        success: ''
      };
    case FAILED_3D_SECURE:
      return {
        ...state,
        userSubscriptionId: action.userSubscriptionId,
        error: action.error,
        loading: action.loading
      };
    case START_3D_SECURE:
      return {
        ...state,
        loading: action.loading
      };
    case LOGOUT:
      console.log('logout', action);
      return {
        ...state,
        user: action.user
      };
    default:
      return payment(state, action);
  }
}

// Sign Up Action creators

// export const logoutUser = () => dispatch => {
//   localStorage.removeItem('FAMESTER_TOKEN');
//   localStorage.removeItem('FAMESTER_USER');
//   dispatch(logout());
// };

// const authUser = url => {
//   return function(name, email, password) {
//     return async function(dispatch) {
//       dispatch(signUpRequest());
//       try {
//         const {
//           data: { token, user }
//         } = await axios.post(
//           `${process.env.REACT_APP_SERVER_API}/user/${url}`,
//           {
//             name,
//             email,
//             password
//           }
//         );
//         console.log('user, token', token, user);
//         localStorage.setItem('FAMESTER_TOKEN', token);
//         localStorage.setItem('FAMESTER_USER', JSON.stringify(user));
//         dispatch(signUpResult(user, token));
//       } catch (e) {
//         console.log('err', e);
//         dispatch(signUpFail());
//       }
//     };
//   };
// };

// export const signUpUser = authUser('sign-up');
// export const signInUser = authUser('sign-in');
