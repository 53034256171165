import { createSelector } from 'reselect';

const getConverterVoices = state => state.converter.voices;

const voiceLanguageSelector = createSelector(
  [getConverterVoices],
  voices => {
    return voices.reduce((acc, { LanguageName, LanguageCode }) => {
      const foundedLang = acc.find(
        voice => voice.LanguageCode === LanguageCode
      );
      if (foundedLang) {
        return acc;
      }
      acc.push({
        LanguageCode,
        LanguageName
      });
      return acc;
    }, []);
  }
);

export default voiceLanguageSelector;
