// export const ADD_PAYMENT_SUBSCRIPTION_REQUEST =
//   'ADD_PAYMENT_SUBSCRIPTION_REQUEST';
// export const ADD_PAYMENT_SUBSCRIPTION_RESULT =
//   'ADD_PAYMENT_SUBSCRIPTION_RESULT';
// export const ADD_PAYMENT_SUBSCRIPTION_FAIL = 'ADD_PAYMENT_SUBSCRIPTION_FAIL';
export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST';
export const UPDATE_CARD_RESULT = 'UPDATE_CARD_RESULT';
export const UPDATE_CARD_FAIL = 'UPDATE_CARD_FAIL';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_RESULT = 'CANCEL_SUBSCRIPTION_RESULT';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_RESULT = 'GET_SUBSCRIPTION_RESULT';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';
export const RESTORE_SUBSCRIPTION_REQUEST = 'RESTORE_SUBSCRIPTION_REQUEST';
export const RESTORE_SUBSCRIPTION_RESULT = 'RESTORE_SUBSCRIPTION_RESULT';
export const RESTORE_SUBSCRIPTION_FAIL = 'RESTORE_SUBSCRIPTION_FAIL';
export const CLEAR_PAYMENT_ERROR = 'CLEAR_PAYMENT_ERROR';
export const CLEAR_PAYMENT_SUCCESS = 'CLEAR_PAYMENT_SUCCESS';
export const UPGRADE_SUBSCRIPTION_REQUEST = 'UPGRADE_SUBSCRIPTION_REQUEST';
export const UPGRADE_SUBSCRIPTION_RESULT = 'UPGRADE_SUBSCRIPTION_RESULT';
export const UPGRADE_SUBSCRIPTION_FAIL = 'UPGRADE_SUBSCRIPTION_FAIL';
export const EXTEND_PACKAGE_LIMIT_REQUEST = 'EXTEND_PACKAGE_LIMIT_REQUEST';
export const EXTEND_PACKAGE_LIMIT_RESULT = 'EXTEND_PACKAGE_LIMIT_RESULT';
export const EXTEND_PACKAGE_LIMIT_FAIL = 'EXTEND_PACKAGE_LIMIT_FAIL';
export const APPLY_DISCOUNT_REQUEST = 'APPLY_DISCOUNT_REQUEST';
export const APPLY_DISCOUNT_RESULT = 'APPLY_DISCOUNT_RESULT';
export const APPLY_DISCOUNT_FAIL = 'APPLY_DISCOUNT_FAIL';

// export function addPaymentSubscriptionRequest(name, email, plan, stripeToken) {
//   console.log('subsRequest', name, email, plan, stripeToken);
//   return {
//     type: ADD_PAYMENT_SUBSCRIPTION_REQUEST,
//     loading: true,
//     name,
//     email,
//     plan,
//     stripeToken
//   };
// }

// export function addPaymentSubscriptionResult({ subscription, limits }) {
//   return {
//     type: ADD_PAYMENT_SUBSCRIPTION_RESULT,
//     loading: false,
//     subscription,
//     limits
//   };
// }

// export function addPaymentSubscriptionFail(error) {
//   return {
//     type: ADD_PAYMENT_SUBSCRIPTION_FAIL,
//     loading: false,
//     error: error ? error.response : null
//   };
// }

export function updateCardRequest(email, paymentMethod) {
  console.log('updateCardReq', email, paymentMethod);
  return {
    type: UPDATE_CARD_REQUEST,
    loading: true,
    email,
    paymentMethod
  };
}

export function updateCardResult({
  charsLimit,
  paid,
  subscriptionEnd,
  onlyCardUpdate = false
}) {
  console.log(
    'updateCardRes',
    charsLimit,
    paid,
    subscriptionEnd,
    onlyCardUpdate
  );
  return {
    type: UPDATE_CARD_RESULT,
    loading: false,
    charsLimit,
    paid,
    subscriptionEnd,
    onlyCardUpdate,
    success: onlyCardUpdate
      ? 'Card updated'
      : 'Card updated. Payment was successful!'
  };
}

export function updateCardFail(error) {
  return {
    type: UPDATE_CARD_FAIL,
    loading: false,
    error
  };
}

export function cancelSubscriptionRequest(email) {
  return {
    type: CANCEL_SUBSCRIPTION_REQUEST,
    loading: true,
    email
  };
}

export function cancelSubscriptionResult({ message }) {
  return {
    type: CANCEL_SUBSCRIPTION_RESULT,
    loading: false,
    success: message,
    cancel: true
  };
}

export function cancelSubscriptionFail(error) {
  return {
    type: CANCEL_SUBSCRIPTION_FAIL,
    loading: false,
    error
  };
}

export function getSubscriptionRequest(email) {
  return {
    type: GET_SUBSCRIPTION_REQUEST,
    loading: true,
    email
  };
}

export function getSubscriptionResult({ subscription }) {
  return {
    type: GET_SUBSCRIPTION_RESULT,
    loading: false,
    subscription
  };
}

export function getSubscriptionFail(error) {
  console.log('error', error.response);
  return {
    type: GET_SUBSCRIPTION_FAIL,
    loading: false,
    error: error ? error.response : null
  };
}

export function applyDiscountRequest(discount) {
  return {
    type: APPLY_DISCOUNT_REQUEST,
    loading: true,
    discount
  };
}

export function applyDiscountResult({ discount }) {
  return {
    type: APPLY_DISCOUNT_RESULT,
    loading: false,
    discount
  };
}

export function applyDiscountFail(error) {
  console.log('error', error.response);
  return {
    type: APPLY_DISCOUNT_FAIL,
    loading: false,
    error: error ? error.response : null
  };
}

export function restoreSubscriptionRequest(email) {
  return {
    type: RESTORE_SUBSCRIPTION_REQUEST,
    loading: true,
    email
  };
}

export function restoreSubscriptionResult({ message }) {
  return {
    type: RESTORE_SUBSCRIPTION_RESULT,
    loading: false,
    cancel: false,
    success: message
  };
}

export function restoreSubscriptionFail(error) {
  return {
    type: RESTORE_SUBSCRIPTION_FAIL,
    loading: false,
    error
  };
}

export function upgradeSubscriptionRequest(
  email,
  plan,
  paymentMethod,
  discount
) {
  return {
    type: UPGRADE_SUBSCRIPTION_REQUEST,
    loading: true,
    email,
    plan,
    paymentMethod,
    discount
  };
}

export function upgradeSubscriptionResult({
  subscription,
  user,
  limits,
  message
}) {
  console.log('upgradeSubs', subscription, message);
  return {
    type: UPGRADE_SUBSCRIPTION_RESULT,
    loading: false,
    subscription,
    user,
    limits,
    success: message
  };
}

export function upgradeSubscriptionFail(error) {
  return {
    type: UPGRADE_SUBSCRIPTION_FAIL,
    loading: false,
    error
  };
}

export function extendPackageLimitRequest(email, packageCharsLimit) {
  return {
    type: EXTEND_PACKAGE_LIMIT_REQUEST,
    loading: true,
    email,
    packageCharsLimit
  };
}

export function extendPackageLimitResult({ packageCharsLimit, message }) {
  return {
    type: EXTEND_PACKAGE_LIMIT_RESULT,
    loading: false,
    packageCharsLimit,
    success: message
  };
}

export function extendPackageLimitFail(error) {
  return {
    type: EXTEND_PACKAGE_LIMIT_FAIL,
    loading: false,
    error
  };
}

export function clearPaymentError() {
  return {
    type: CLEAR_PAYMENT_ERROR
  };
}

export function clearPaymentSuccess() {
  return {
    type: CLEAR_PAYMENT_SUCCESS
  };
}
