export const CONVERT_TEXT_REQUEST = 'CONVERT_TEXT_REQUEST';
export const CONVERT_TEXT_RESULT = 'CONVERT_TEXT_RESULT';
export const CONVERT_TEXT_FAIL = 'CONVERT_TEXT_FAIL';
export const CLEAR_CONVERTER_TEXT_ERROR = 'CLEAR_CONVERTER_TEXT_ERROR';
export const CONVERTER_HISTORY_REQUEST = 'CONVERTER_HISTORY_REQUEST';
export const CONVERTER_HISTORY_RESULT = 'CONVERTER_HISTORY_RESULT';
export const CONVERTER_HISTORY_FAIL = 'CONVERTER_HISTORY_FAIL';
export const START_CONVERTER_TASK_POLLING = 'START_CONVERTER_TASK_POLLING';
export const STOP_CONVERTER_TASK_POLLING = 'STOP_CONVERTER_TASK_POLLING';
export const FAIL_CONVERTER_TASK_POLLING = 'FAIL_CONVERTER_TASK_POLLING';
export const CONVERTER_TASK_POLLING_RESULT = 'CONVERTER_TASK_POLLING_RESULT';
export const CONVERTER_RESET_AUDIO_REQUEST = 'CONVERTER_RESET_AUDIO_REQUEST';
export const CONVERTER_GET_VOICES_REQUEST = 'CONVERTER_GET_VOICES_REQUEST';
export const CONVERTER_GET_VOICES_RESULT = 'CONVERTER_GET_VOICES_RESULT';
export const CONVERTER_GET_VOICES_FAIL = 'CONVERTER_GET_VOICES_FAIL';
export const CHANGE_VOICE_LANGUAGE = 'CHANGE_VOICE_LANGUAGE';
export const FREE_VOICES_FLAG = 'FREE_VOICES_FLAG';

export function convertTextRequest(
  username,
  email,
  voiceId,
  text,
  free = false,
  textType,
  lang,
  gender,
  engine
) {
  return {
    type: CONVERT_TEXT_REQUEST,
    loading: true,
    text,
    textType,
    voiceId,
    username,
    email,
    free,
    lang,
    gender,
    engine
  };
}

export function convertTextResult(data) {
  console.log('convertTextResultAction', data);
  return {
    type: CONVERT_TEXT_RESULT,
    loading: false,
    audioTask: data.task
  };
}

export function convertFail(error) {
  return {
    type: CONVERT_TEXT_FAIL,
    loading: false,
    converterError: error
  };
}

export function clearConverterTextError() {
  return {
    type: CLEAR_CONVERTER_TEXT_ERROR,
    converterError: ''
  };
}

export function converterHistoryRequest(email, limit, lte) {
  console.log('historyRequest', email);
  return {
    type: CONVERTER_HISTORY_REQUEST,
    loading: true,
    email,
    limit,
    lte
  };
}

export function converterHistoryResult(data) {
  console.log('historyAction', data);
  return {
    type: CONVERTER_HISTORY_RESULT,
    loading: false,
    tasks: data.tasks.Items,
    hasMore: data.tasks.Items.length !== 0
  };
}

export function converterHistoryFail() {
  return {
    type: CONVERTER_HISTORY_FAIL,
    loading: false
  };
}

export function startConverterTaskPolling(taskId) {
  return {
    type: START_CONVERTER_TASK_POLLING,
    Id: taskId,
    loading: true
  };
}

export function stopConverterTaskPolling() {
  return {
    type: STOP_CONVERTER_TASK_POLLING,
    loading: false
  };
}

export function failConverterTaskPolling(error) {
  return {
    type: FAIL_CONVERTER_TASK_POLLING,
    loading: false,
    error
  };
}

export function converterTaskPollingResult(data) {
  console.log('converterTaskResultAction', data);
  return {
    type: CONVERTER_TASK_POLLING_RESULT,
    audioTask: data.task
  };
}

export function converterResetAudioRequest() {
  return {
    type: CONVERTER_RESET_AUDIO_REQUEST,
    audioTask: {}
  };
}

export function converterGetVoicesRequest(free = false) {
  return {
    type: CONVERTER_GET_VOICES_REQUEST,
    voicesLoading: true,
    free
  };
}

export function converterGetVoicesResult({ Voices }) {
  console.log('VoicesData', Voices);
  return {
    type: CONVERTER_GET_VOICES_RESULT,
    voicesLoading: false,
    voices: Voices
  };
}

export function converterGetVoicesFail(e) {
  return {
    type: CONVERTER_GET_VOICES_FAIL,
    voicesLoading: false
  };
}

export function changeVoiceLanguage(lang) {
  return {
    type: CHANGE_VOICE_LANGUAGE,
    voiceLanguage: lang
  };
}

export function changeFreeVoicesFlag(freeVoicesFlag) {
  return {
    type: FREE_VOICES_FLAG,
    freeVoicesFlag
  };
}
