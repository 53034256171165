import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MuiSnackbar } from '@material-ui/core';

import SnackbarContent from './SnackbarContent';

const Snackbar = ({
  variant,
  message,
  onClose = () => {},
  vertical = 'bottom',
  horizontal = 'left',
  autoHideDuration = 4000
}) => {
  const [opened, setOpen] = useState(false);
  const onCloseHandler = () => {
    setOpen(false);
    onClose();
  };
  useEffect(() => {
    if (message && !opened) {
      setOpen(true);
    } else if (!message && opened) {
      setOpen(false);
    }
  }, [message, opened, setOpen]);
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical,
        horizontal
      }}
      open={opened}
      autoHideDuration={autoHideDuration}
      onClose={onCloseHandler}
    >
      <SnackbarContent
        onClose={onCloseHandler}
        variant={variant}
        message={message}
      />
    </MuiSnackbar>
  );
};

Snackbar.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  autoHideDuration: PropTypes.any
};

export default Snackbar;
