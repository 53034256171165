import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import cx from 'classnames';

const useStyles = makeStyles(theme => {
  return {
    root: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    titleSection: {
      position: 'relative',
      padding: theme.spacing(7, 8, 7, 8),
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 30,
        height: 4,
        margin: theme.spacing(3, 0, 5, 0),
        backgroundColor: '#707070',
        top: 0
      }
    },
    title: {
      fontWeight: 700
    },
    subtitle: {
      color: theme.palette.grey['600']
    },
    buttonContainer: {
      marginTop: 40
    }
  };
});

const FormPage = ({
  title,
  titleClassName = '',
  wrapperClassname = '',
  innerWrapperClassName = '',
  titleSectionClassame = '',
  subtitle = '',
  children,
  xs = 10,
  sm = 6,
  md = 4,
  lg = 3
}) => {
  const classes = useStyles();
  return (
    <Grid container className={cx(classes.root, wrapperClassname)}>
      <Grid
        item
        xs={xs}
        sm={sm}
        lg={lg}
        md={md}
        className={innerWrapperClassName}
      >
        {(title || subtitle) && (
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={cx(classes.titleSection, titleSectionClassame)}
          >
            {title && (
              <Typography
                align="center"
                component="h1"
                variant="h4"
                color="textPrimary"
                className={classes.title}
                gutterBottom
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.subtitle}
              >
                {subtitle}
              </Typography>
            )}
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

FormPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  titleClassName: PropTypes.any,
  wrapperClassname: PropTypes.string,
  innerWrapperClassName: PropTypes.string,
  titleSectionClassame: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number
};

export default FormPage;
