import React from 'react';
import Footer from '../footer/Footer';
import TextSection from '../../ui/Page/TextSection';

const ContactUs = () => {
  return (
    <>
      <TextSection title="Contact Us" section="Contact Us">
        <p>
          NaturalTTS Is a service that has been created by an enthusiastic team
          of professionals developers who were inspired by numerous problems
          that could be solved with innovative natural text to speech converter.
        </p>

        <p>
          We are always happy to hear any feedback from you. Feel free to send
          your requests through your personal account contact form or hit us at{' '}
          <a href="mailto:mailing@naturaltts.com">mailing@naturaltts.com</a>.
        </p>
      </TextSection>
      <Footer />
    </>
  );
};

export default ContactUs;
