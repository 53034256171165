import {
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_RESULT,
  UPDATE_CARD_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_RESULT,
  CANCEL_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_RESULT,
  GET_SUBSCRIPTION_FAIL,
  RESTORE_SUBSCRIPTION_REQUEST,
  RESTORE_SUBSCRIPTION_RESULT,
  RESTORE_SUBSCRIPTION_FAIL,
  UPGRADE_SUBSCRIPTION_REQUEST,
  UPGRADE_SUBSCRIPTION_RESULT,
  UPGRADE_SUBSCRIPTION_FAIL,
  EXTEND_PACKAGE_LIMIT_REQUEST,
  EXTEND_PACKAGE_LIMIT_RESULT,
  EXTEND_PACKAGE_LIMIT_FAIL,
  CLEAR_PAYMENT_ERROR,
  CLEAR_PAYMENT_SUCCESS,
  APPLY_DISCOUNT_REQUEST,
  APPLY_DISCOUNT_RESULT,
  APPLY_DISCOUNT_FAIL
} from '../actions/paymentActions';
import { getErrorMessage } from '../utils';

export default function reducer(
  state,
  {
    type,
    loading,
    error,
    subscription,
    cancel,
    charsLimit,
    paid,
    user,
    subscriptionEnd,
    packageCharsLimit,
    onlyCardUpdate,
    discount,
    success,
    limits
  }
) {
  switch (type) {
    case UPDATE_CARD_REQUEST:
      return {
        ...state,
        loading: loading
      };
    case UPDATE_CARD_RESULT:
      if (onlyCardUpdate) {
        return {
          ...state,
          loading,
          user: {
            ...state.user,
            paid
          },
          success
        };
      }
      return {
        ...state,
        loading,
        user: {
          ...state.user,
          charsLimit,
          paid,
          subscriptionEnd
        },
        success
      };
    case UPDATE_CARD_FAIL:
      return {
        ...state,
        loading,
        error: getErrorMessage(error)
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading
      };
    case CANCEL_SUBSCRIPTION_RESULT:
      return {
        ...state,
        loading,
        user: {
          ...state.user,
          cancel
        },
        success
      };
    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading
      };
    case APPLY_DISCOUNT_REQUEST:
      return {
        ...state,
        loading
      };
    case APPLY_DISCOUNT_RESULT:
      return {
        ...state,
        loading,
        user: {
          ...state.user,
          discount
        }
      };
    case APPLY_DISCOUNT_FAIL:
      return {
        ...state,
        loading,
        error: getErrorMessage(error)
      };
    case GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading
      };
    case GET_SUBSCRIPTION_RESULT:
      return {
        ...state,
        loading,
        user: {
          ...state.user,
          ...subscription,
          charsLimit: undefined,
          packageCharsLimit: undefined
        },
        limits: {
          charsLimit: subscription.charsLimit,
          packageCharsLimit: subscription.packageCharsLimit
        }
      };
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading,
        error: error.data.message
      };
    case RESTORE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading
      };
    case RESTORE_SUBSCRIPTION_RESULT:
      return {
        ...state,
        user: {
          ...state.user,
          cancel
        },
        success,
        loading
      };
    case RESTORE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading,
        error: getErrorMessage(error)
      };
    case UPGRADE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading
      };
    case UPGRADE_SUBSCRIPTION_RESULT:
      return {
        ...state,
        user: {
          ...state.user,
          ...(user ? user : {}),
          ...subscription
        },
        limits: {
          ...state.limits,
          ...limits
        },
        success,
        loading
      };
    case UPGRADE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading,
        error: getErrorMessage(error)
      };
    case EXTEND_PACKAGE_LIMIT_REQUEST:
      return {
        ...state,
        loading
      };
    case EXTEND_PACKAGE_LIMIT_RESULT:
      return {
        ...state,
        limits: {
          ...state.limits,
          packageCharsLimit
        },
        success,
        loading
      };
    case EXTEND_PACKAGE_LIMIT_FAIL:
      return {
        ...state,
        error: getErrorMessage(error),
        loading
      };
    case CLEAR_PAYMENT_ERROR:
      return {
        ...state,
        error: ''
      };
    case CLEAR_PAYMENT_SUCCESS:
      return {
        ...state,
        success: ''
      };
    default:
      return state;
  }
}
