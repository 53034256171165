import {
  SUPPORT_QUESTION_REQUEST,
  SUPPORT_QUESTION_RESULT,
  SUPPORT_QUESTION_FAIL,
  CLEAR_SUPPORT_QUESTION_ERROR,
  CLEAR_SUPPORT_QUESTION_SUCCESS
} from '../actions/supportActions';
import { getErrorMessage } from '../utils';

const initialState = {
  loading: false,
  error: null,
  success: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUPPORT_QUESTION_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    case SUPPORT_QUESTION_RESULT:
      return {
        ...state,
        loading: action.loading,
        success: action.success
      };
    case SUPPORT_QUESTION_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: getErrorMessage(action.error)
      };
    case CLEAR_SUPPORT_QUESTION_ERROR:
      return {
        ...state,
        error: ''
      };
    case CLEAR_SUPPORT_QUESTION_SUCCESS:
      return {
        ...state,
        success: ''
      };
    default:
      return state;
  }
}
