/* eslint-disable-next-line */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import config from '@werter12/naturaltts-config';

import { getSafe } from '../../../utils';
import inlineGuard from './inlineGuard';

function ComponentGuard({ children, permissions, plan, customChecker }) {
  let allowed = true;
  if (!plan) return null;
  const matchPermissions = inlineGuard(plan, permissions);

  if (customChecker) {
    allowed = customChecker(plan, permissions, config.PLANS);
  }

  return matchPermissions && allowed
    ? typeof children === 'function'
      ? children(config.PLANS[plan].permissions)
      : children
    : null;
}

ComponentGuard.propTypes = {
  children: PropTypes.any,
  plan: PropTypes.string,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customChecker: PropTypes.func
};

function mapStateToProps(state) {
  return {
    plan: getSafe(() => state.auth.user.plan, '')
  };
}
export default connect(mapStateToProps, null)(ComponentGuard);
