import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: ({ active }) => ({
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.htmlFontSize,
      '&:hover': {
        cursor: 'pointer'
      },
      '&::after': {
        right: active ? '0 !important' : ''
      }
    })
  };
});

function Link({
  to,
  children,
  className: customClassname,
  disabled,
  underline = 'none',
  onClick,
  ...rest
}) {
  const linkStyles = useStyles(rest);
  const compProps = {
    to,
    classes: linkStyles,
    className: customClassname,
    component: disabled ? 'span' : RouterLink,
    underline: underline,
    onClick
  };
  return <MuiLink {...compProps}>{children}</MuiLink>;
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  underline: PropTypes.string,
  onClick: PropTypes.func
};

export default Link;
